import {useParams} from 'react-router-dom';
import {useAppDispatch} from '../../../shared/hooks/stateHooks';
import {useEffect, useState} from 'react';
import PrivateLayout from '../../../shared/components/PrivateLayout/PrivateLayout';
import {Box, CircularProgress, Container} from '@mui/material';
import {useAforableById} from "../../../aforable/hooks/useAforableById";
import {fetchAforableById} from "../../../aforable/state/aforableSlice";
import AforableUpgradePremiumSuccess from "../../components/AforableUpgradePremiumSuccess/AforableUpgradePremiumSuccess";

function AforableUpgradePremiumSuccessPage() {

    const [isOrderCompleted, setOrderCompleted] = useState(false);
    const params = useParams();
    const dispatch = useAppDispatch();

    const aforableId = params.id!;
    const orderId = params.orderId!;

    const aforable = useAforableById(aforableId);

    useEffect(() => {
        dispatch(fetchAforableById({aforableId}));
    }, [aforableId, dispatch]);

    useEffect(() => {
        (async () => {
            //await dispatch(completeOrder({orderId})).unwrap();
            setOrderCompleted(true);
        })();
    }, [dispatch, orderId]);

    return (
        <PrivateLayout>
            <Container maxWidth={'sm'} sx={{px: 0}}>
                { aforable && isOrderCompleted ? (
                    <>
                        <AforableUpgradePremiumSuccess aforable={aforable} />
                    </>
                ): (
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress/>
                    </Box>
                )}
            </Container>
        </PrivateLayout>
    );
}

export default AforableUpgradePremiumSuccessPage;
