import {Offer} from "../Offer";
import {OfferDuration} from "../OfferDuration";
import {OfferPricing} from "../OfferPricing";

export class OfferBuilder {

    build(data: any, aforableId: string): Offer {

        return new Offer(
            data.id,
            aforableId,
            new OfferPricing(
                data.pricing.amount,
                data.pricing.currency
            ),
            new OfferDuration(
                data.duration.amount,
                data.duration.timeUnit
            ),
            data.isFreeTrial
        );

    }
}
