import {Box, Button, CircularProgress, Grid, TextField} from '@mui/material';
import React from 'react';
import LogInEmailFormProps from './LogInEmailFormProps';
import {loginWithEmail} from '../../../../../state/authSlice';
import {useAppDispatch} from '../../../../../../shared/hooks/stateHooks';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {useTranslate} from "../../../../../../shared/hooks/useTranslate";
import ForgotPasswordLink from "../../../../shared/ForgotPasswordLink/ForgotPasswordLink";
import SignUpLink from "../../../../shared/SignUpLink/SignUpLink";

function LogInEmailForm(props: LogInEmailFormProps) {

    const {auth} = props;

    const dispatch = useAppDispatch();
    const { t, td } = useTranslate('auth', 'LogInPage.LogInEmailForm');

    const initialValues = {
        email: '',
        password: '',
    };

    const validationSchema = Yup.object({
        email: Yup.string().required(td('validation.required')),
        password: Yup.string().required(td('validation.required'))
    });

    const onSubmit = async (values: any, actions: any) => {

        const payload = {
            email: values.email as string,
            password: values.password as string,
        };

        try {
            await dispatch(loginWithEmail(payload)).unwrap();
        } catch (error) {
            actions.setFieldError('password', t('wrong_email_or_password'))
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    return (
        <Box sx={{mt: 1}} width="100%">
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    margin="normal"
                    fullWidth
                    id="email"
                    label={td('field.email')}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    name="password"
                    label={td('field.password')}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                    disabled={auth.isPending}
                >
                    {auth.isPending && <CircularProgress size={25}/>}
                    {!auth.isPending && td('action.login')}
                </Button>
                <Grid container>
                    <Grid item xs>
                        <ForgotPasswordLink/>
                    </Grid>
                    <Grid item>
                        <SignUpLink/>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}

export default LogInEmailForm
