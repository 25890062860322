import Link from "../../../../shared/components/Link/Link";
import React from "react";
import {useTranslate} from "../../../../shared/hooks/useTranslate";

function ForgotPasswordLink() {

    const { t } = useTranslate('auth', 'Component.ForgotPasswordLink')

    return (
        <Link to="/forgot-password" variant="body2" color="primary">
            {t('forgot_password')}
        </Link>
    );
}

export default ForgotPasswordLink;