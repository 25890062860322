
export class OfferPricing {
    constructor(
        private _amount: number,
        private _currency: string
    ) {}


    get amount(): number {
        return this._amount;
    }

    get currency(): string {
        return this._currency;
    }
}