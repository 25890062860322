import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../../auth/state/authSlice'
import organizationReducer from '../../organization/state/organizationSlice'
import organizationMemberReducer from '../../organizationMember/state/organizationMemberSlice'
import aforableReducer from '../../aforable/state/aforableSlice'
import orderReducer from '../../order/state/orderSlice'
import userReducer from '../../user/state/userSlice'
import offerReducer from "../../offer/state/offerSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        organization: organizationReducer,
        organizationMember: organizationMemberReducer,
        aforable: aforableReducer,
        order: orderReducer,
        offer: offerReducer
    },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
