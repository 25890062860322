import PrivateLayout from '../../../shared/components/PrivateLayout/PrivateLayout';
import ProfileDetail from '../../components/ProfileDetail/ProfileDetail';
import SwitchLanguage from '../../components/SwitchLanguage/SwitchLanguage';
import {Box, CircularProgress, Container} from '@mui/material';
import ChangePassword from '../../components/ChangePassword/ChangePassword';
import Logout from '../../components/Logout/Logout';
import {useAppDispatch} from '../../../shared/hooks/stateHooks';
import {useEffect} from 'react';
import {fetchUserLogged} from '../../state/userSlice';
import {useUserLogged} from '../../../auth/hooks/useUserLogged';

function ProfilePage() {

    const dispatch = useAppDispatch();
    const userLogged = useUserLogged();

    useEffect(() => {
        dispatch(fetchUserLogged());
    }, [dispatch]);

    return (
        <PrivateLayout>
            <Container maxWidth={'sm'} sx={{px: 0}}>
                { userLogged ? (
                    <>
                        <ProfileDetail/>
                        <Box sx={{m: 2}}/>

                        <SwitchLanguage/>
                        <Box sx={{m: 2}}/>

                        <ChangePassword/>
                        <Box sx={{m: 2}}/>

                        <Logout/>
                    </>
                ): (
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress/>
                    </Box>
                )}

            </Container>
        </PrivateLayout>
    );
}

export default ProfilePage;
