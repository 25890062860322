import {Box, Button, CircularProgress, TextField} from '@mui/material';
import React from 'react';
import * as Yup from "yup";
import {useFormik} from "formik";
import {ResetPasswordFormProps} from "./ResetPasswordFormProps";

function ResetPasswordForm(props: ResetPasswordFormProps) {

    const initialValues = {
        password: '',
        passwordConfirmation: ''
    };

    const validationSchema = Yup.object({
        password: Yup.string()
            .required('Required')
            .min(6, 'Must be 6 characters or more'),
        passwordConfirmation: Yup.string()
            .required('Required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: props.onSubmit,
    });

    return (
        <Box sx={{mt: 1}} width="100%">
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    margin="normal"
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    autoComplete="password"
                    autoFocus
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="passwordConfirmation"
                    label="Password Confirmation"
                    name="passwordConfirmation"
                    autoComplete="password"
                    type="password"
                    value={formik.values.passwordConfirmation}
                    onChange={formik.handleChange}
                    error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
                    helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                    disabled={props.inProgress}
                >
                    {props.inProgress && <CircularProgress size={25}/>}
                    {!props.inProgress && 'Reset password'}
                </Button>
            </form>
        </Box>
    );
}

export default ResetPasswordForm
