import {Aforable} from '../domain/Aforable';
import {useAforableMap} from "./useAforableMap";

export const useAforableById = (aforableId?: string): Aforable | undefined => {

    const aforableListById = useAforableMap();

    if (!aforableId) {
        return;
    }

    return aforableListById[aforableId];

}
