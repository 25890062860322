import {Order} from "../../domain/Order";
import {OrderSerialization} from "./OrderSerialization";
import {OrderActivationDateRange} from "../../domain/OrderActivationDateRange";
import {OrderPricing} from "../../domain/OrderPricing";
import {OrderMetadataBuilder} from "../../domain/builder/OrderMetadataBuilder";

export class OrderSerializer {

    private metadataBuilder: OrderMetadataBuilder;

    constructor() {
        this.metadataBuilder = new OrderMetadataBuilder();
    }

    serialize(order: Order): OrderSerialization {
        return {
            id: order.id,
            aforableId: order.aforableId,
            createdAt: order.createdAt.getTime(),
            activationDateRange: {
                startDate: order.activationDateRange.startDate.getTime(),
                endDate: order.activationDateRange.endDate.getTime(),
            },
            pricing: {
                amount: order.pricing.amount,
                currency: order.pricing.currency
            },
            status: order.status,
            isActive: order.isActive,
            isFreeTrial: order.isFreeTrial,
            metadata: order.metadata?.serialize()
        }
    }

    deserialize(serialization: OrderSerialization): Order {

        return new Order(
            serialization.id,
            serialization.aforableId,
            new Date(serialization.createdAt),
            new OrderActivationDateRange(
                new Date(serialization.activationDateRange.startDate),
                new Date(serialization.activationDateRange.endDate),
            ),
            new OrderPricing(
                serialization.pricing.amount,
                serialization.pricing.currency
            ),
            serialization.status,
            serialization.isActive,
            serialization.isFreeTrial,
            this.metadataBuilder.build(serialization.metadata)
        );

    }
}