
import {OfferCollection} from "../domain/OfferCollection";
import {OfferSerializer} from "../state/serialization/offerSerializer";
import {useOffer} from "./useOffer";
import {OfferSerialization} from "../state/serialization/OfferSerialization";

const serializer = new OfferSerializer();

export const useOfferList = (): OfferCollection => {

    const offerState = useOffer();
    const offers = Object
        .values(offerState.listById)
        .map((serialization: OfferSerialization) => serializer.deserialize(serialization));
    return new OfferCollection(offers);
}
