import {Offer} from "../../domain/Offer";
import {OfferSerialization} from "./OfferSerialization";
import {OfferPricing} from "../../domain/OfferPricing";
import {OfferDuration} from "../../domain/OfferDuration";

export class OfferSerializer {

    serialize(offer: Offer): OfferSerialization {
        return {
            id: offer.id,
            aforableId: offer.aforableId,
            pricing: {
                amount: offer.pricing.amount,
                currency: offer.pricing.currency
            },
            duration: {
                amount: offer.duration.amount,
                timeUnit: offer.duration.timeUnit
            },
            isFreeTrial: offer.isFreeTrial
        };
    }

    deserialize(serialization: OfferSerialization): Offer {
        return new Offer(
          serialization.id,
          serialization.aforableId,
          new OfferPricing(
              serialization.pricing.amount,
              serialization.pricing.currency
          ),
          new OfferDuration(
              serialization.duration.amount,
              serialization.duration.timeUnit
          ),
          serialization.isFreeTrial
        );
    }
}