import {Aforable} from '../domain/Aforable';
import {useAforable} from './useAforable';
import {AforableSerializer} from "../state/serialization/aforableSerializer";
import {AforableSerialization} from "../state/serialization/AforableSerialization";

const serializer = new AforableSerializer();

export const useAforableList = (): Aforable[] => {
    const aforableState = useAforable();
    return Object
        .values(aforableState.listById)
        .map((object: AforableSerialization) => serializer.deserialize(object));
}
