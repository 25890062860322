import {Organization} from '../domain/Organization';
import {useOrganization} from './useOrganization';

export const useOrganizationById = (organizationId?: string): Organization | undefined => {
    const organization = useOrganization();

    if (!organizationId) {
        return;
    }

    return organization.listById[organizationId];

}
