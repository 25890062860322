import axios from 'axios';
import ApiAbstractRepository from '../../shared/repository/ApiAbstractRepository';
import {Organization} from '../domain/Organization';

export class ApiOrganizationRepository extends ApiAbstractRepository{

    async editOrganization(organization: Organization): Promise<void> {
        const config = this.authConfig();

        const data = {
            name: organization.name,
            sector: organization.sector,
            address: organization.address,
            taxId: organization.taxId,
            currency: organization.currency
        };

        return await axios
            .put(this.baseUrl + `/organizations/${organization.id}`, data, config )
            .then(() => {})
            .catch((e) => this.handleFailureRequest(e));
    }

    async createOrganization(organization: Organization): Promise<void> {
        const config = this.authConfig();

        const data = {
            id: organization.id,
            name: organization.name,
            sector: organization.sector,
            address: organization.address,
            taxId: organization.taxId,
            currency: organization.currency
        };

        return await axios
            .post(this.baseUrl + `/organizations`, data, config )
            .then(() => {})
            .catch((e) => this.handleFailureRequest(e));
    }

}
