import {EditProfileFormProps} from './EditProfileFormProps';
import {useAppDispatch} from '../../../shared/hooks/stateHooks';
import React, {useState} from 'react';
import * as Yup from 'yup';
import {User} from '../../domain/User';
import {useFormik} from 'formik';
import {
    Avatar,
    Box, Button,
    Card,
    CardActions,
    CardContent,
    FormControl,
    TextField,
    Typography
} from '@mui/material';
import {AlternateEmail, BadgeOutlined, Person, Save} from '@mui/icons-material';
import {editUser} from '../../state/userSlice';
import {useNavigate} from 'react-router-dom';
import {useTranslate} from "../../../shared/hooks/useTranslate";

function EditProfileForm(props: EditProfileFormProps) {

    const {userLogged} = props;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isSubmitDisabled, setSubmitDisabled] = useState(false);
    const { t, td } = useTranslate('user', 'EditProfilePage.EditProfileForm')

    const initialValues = {
        firstName: userLogged?.firstName || '',
        lastName: userLogged?.lastName || '',
        email: userLogged?.email || ''
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
    });

    const onSubmit = async (values: any, actions: any) => {

        setSubmitDisabled(true);
        const user: User = {
            ...userLogged!,
            firstName: values.firstName as string,
            lastName: values.lastName as string
        };

        await dispatch(editUser({user})).unwrap();
        setSubmitDisabled(false);
        navigate('/profile');

    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });


    return (
        <Card>
            <form onSubmit={formik.handleSubmit}>
                <CardContent sx={{pb: 0}}>
                    <Typography component="div" variant="h5">{t('edit_profile')}</Typography>
                    <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                        <Avatar sx={{bgcolor: 'primary.main', mr: 2}}>
                            <Person/>
                        </Avatar>
                        <FormControl fullWidth>
                            <TextField
                                id="firstName"
                                name="firstName"
                                label={t('name')}
                                variant="outlined"
                                type="text"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                            />
                        </FormControl>
                    </Box>

                    <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                        <Avatar sx={{bgcolor: 'primary.main', mr: 2}}>
                            <BadgeOutlined/>
                        </Avatar>
                        <FormControl fullWidth>
                            <TextField
                                id="lastName"
                                name="lastName"
                                label={t('last_name')}
                                variant="outlined"
                                type="text"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                helperText={formik.touched.lastName && formik.errors.lastName}
                            />
                        </FormControl>
                    </Box>

                    <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                        <Avatar sx={{bgcolor: 'primary.main', mr: 2}}>
                            <AlternateEmail/>
                        </Avatar>
                        <FormControl fullWidth>
                            <TextField
                                id="email"
                                name="email"
                                label={t('email')}
                                variant="outlined"
                                type="text"
                                disabled
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </FormControl>
                    </Box>
                </CardContent>
                <CardActions sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button startIcon={<Save/>} color="primary" type="submit" disabled={isSubmitDisabled}>
                        {td('action.save')}
                    </Button>
                </CardActions>
            </form>
        </Card>
    );


}

export default EditProfileForm;
