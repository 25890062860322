import {Avatar, Card, CardActionArea} from '@mui/material';
import * as React from 'react';
import CardContent from '@mui/material/CardContent';
import {
    Add,
} from '@mui/icons-material';
import {Link} from "react-router-dom";

function AddAforableCard() {

    const avatarSize = 60;

    return (
        <Card sx={{ width: "100%", minHeight: 136}} component={Link} to="/spaces/new">
            <CardActionArea sx={{height: "100%"}}>
                <CardContent sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Avatar sx={{ width: avatarSize, height: avatarSize, bgcolor: 'primary.main'}}>
                        <Add sx={{ fontSize: 40 }}/>
                    </Avatar>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default AddAforableCard;
