import {LocalStorageAbstractRepository} from '../../shared/repository/LocalStorageAbstractRepository';
import {AuthState} from '../state/AuthState';

const AUTH: string = 'AUTH';

export class LocalStorageAuthRepository {

    private localStorageRepository: LocalStorageAbstractRepository;

    constructor() {
        this.localStorageRepository = new LocalStorageAbstractRepository();
    }

    authStatus(): AuthState {
        const auth =  this.localStorageRepository.get(AUTH);

        if (!auth) {
            return { isLoggedIn: false, isPending: false }
        }

        return auth;
    }

    setAuthStatus(auth: AuthState): void {
        this.localStorageRepository.set(AUTH, auth);
    }
}
