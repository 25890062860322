import Link from "../../../../shared/components/Link/Link";
import React from "react";
import {useTranslate} from "../../../../shared/hooks/useTranslate";

function SignUpLink() {

    const { t } = useTranslate('auth', 'Component.SignUpLink')

    return (
        <Link to="/signup" variant="body2" color="primary">
            {t('dont_have_an_account_sign_up')}
        </Link>
    );
}

export default SignUpLink;