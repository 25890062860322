import React from 'react';
import {Box, Container} from '@mui/material';
import Copyright from "../Copyright/Copyright";
import LanguageSelector from "../LanguageSelector/LanguageSelector";

function PublicLayout(props: any) {

    return (
        <Container maxWidth="lg" sx={{mt: 5}} >
            {props.children}
            <Box sx={{pt: 2}}>
                <Copyright/>
                <LanguageSelector/>
            </Box>
        </Container>
    );
}

export default PublicLayout;
