import axios from 'axios';
import {User} from '../domain/User';
import ApiAbstractRepository from '../../shared/repository/ApiAbstractRepository';

export class ApiUserRepository extends ApiAbstractRepository {

    async fetchUserLogged(userId: string): Promise<User> {

        const config = this.authConfig();

        return await axios
            .get(this.baseUrl + `/users/${userId}`, config)
            .then( response => response.data.data)
            .catch((e) => this.handleFailureRequest(e));
    }

    async switchLocale(locale: string, userId: string): Promise<void> {

        const config = this.authConfig();

        return await axios
            .put(this.baseUrl + `/users/${userId}/locale`, {locale}, config )
            .then( response => response.data.data)
            .catch((e) => this.handleFailureRequest(e));
    }

    async changePassword(
        userId: string,
        currentPassword: string,
        newPassword: string,
    ): Promise<void> {

        const config = this.authConfig();
        const data = {currentPassword, newPassword};

        return await axios
            .put(this.baseUrl + `/users/${userId}/password`, data, config )
            .then(() => {})
            .catch((e) => this.handleFailureRequest(e));
    }

    async editUser(user: User): Promise<void> {
        const config = this.authConfig();
        const data = {
            firstName: user.firstName,
            lastName: user.lastName
        }

        return await axios
            .put(this.baseUrl + `/users/${user.id}`, data, config )
            .then(() => {})
            .catch((e) => this.handleFailureRequest(e));
    }

    async createUser(user: User, password: string): Promise<void> {

        const data = {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: password,
            roles: user.roles,
            locale: user.locale,
            source: user.source,
            thirdPartyId: user.thirdPartyId
        };

        await axios
            .post(this.baseUrl + '/users', data)
            .catch( e => this.handleFailureRequest(e));
    }
}
