import {useEffect, useState} from "react";
import {FacebookLoginServiceRepository} from "../repository/FacebookLoginServiceRepository";

const useFacebook = (appId: string): FacebookLoginServiceRepository | undefined => {

    const [facebook, setFacebook] = useState<FacebookLoginServiceRepository | undefined>();

    useEffect(() => {

        const repository = () => {
            try {
                return new FacebookLoginServiceRepository(appId);
            }  catch (e) {
                return undefined;
            }
        };

        window.addEventListener('load', () => {
            setFacebook(repository);
        });

        if (!(facebook instanceof FacebookLoginServiceRepository)) {
            setFacebook(repository());
        }

    }, [facebook, appId]);

    return facebook;
};


export default useFacebook;

