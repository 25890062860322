import {
    Box, Button, Divider, Drawer,
    List, ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText, Toolbar
} from "@mui/material";
import React, {useEffect, useState} from 'react';
import TopAppBarDrawerProps from './TopAppBarDrawerProps';
import {Link, useLocation} from 'react-router-dom';
import {options} from './options';
import {currentOptionFinder} from '../AppBarFactory/currentOptionFinder';
import {DrawerOption} from './DrawerOption';
import {useActiveOrganization} from '../../../organization/hooks/useActiveOrganization';
import {useTranslate} from "../../hooks/useTranslate";


function TopAppBarDrawer(props: TopAppBarDrawerProps) {

    const location = useLocation();
    const activeOrganization = useActiveOrganization();
    const { td } = useTranslate();

    const optionList = options(activeOrganization?.id);

    useEffect(() => {
        const optionList = options(activeOrganization?.id);
        setOption(currentOptionFinder(location.pathname, optionList, optionList[0]));
    }, [location.pathname, activeOrganization])

    const [selectedOption, setOption] = useState(currentOptionFinder(location.pathname, optionList, optionList[0]));

    const renderedOptions = optionList.map((item: DrawerOption ) => (
        <ListItem
            key={item.label}
            disablePadding
        >
            <ListItemButton
                component={Link}
                to={item.path}
                selected={selectedOption.path === item.path}
            >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={td(`section.${item.label}`)} />
            </ListItemButton>
        </ListItem>
    ));

    return (
            <Drawer
                anchor="left"
                open={props.isOpen}
                onClose={() => props.toggle(false)}
            >
                <Toolbar />
                <Divider />
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={() => props.toggle(false)}
                    onKeyDown={() => props.toggle(false)}
                >
                    <List>
                        {renderedOptions}
                    </List>
                    <Box sx={{ display:'flex', justifyContent:'center' }}>
                        <Button
                            variant="contained"
                            sx={{ margin: 1, width: "90%" }}
                            onClick={props.logout}
                        >
                            {td('action.logout')}
                        </Button>
                    </Box>
                </Box>
            </Drawer>
    );
}



export default TopAppBarDrawer;
