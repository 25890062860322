import React from 'react';
import PrivateLayout from '../../../shared/components/PrivateLayout/PrivateLayout';
import {Container} from '@mui/material';
import CreateOrganization from "../../components/CreateOrganization/CreateOrganization";

function CreateOrganizationPage() {

    return (
        <PrivateLayout>
            <Container maxWidth={'sm'} sx={{px: 0}}>
                <CreateOrganization/>
            </Container>
        </PrivateLayout>
    );
}

export default CreateOrganizationPage;
