import {useOrder} from './useOrder';
import {OrderSerialization} from "../state/serialization/OrderSerialization";
import {OrderSerializer} from "../state/serialization/orderSerializer";
import {OrderCollection} from "../domain/OrderCollection";

const serializer = new OrderSerializer();

export const useOrderList = (): OrderCollection => {

    const orderState = useOrder();
    const orders = Object
        .values(orderState.listById)
        .map((serialization: OrderSerialization) => serializer.deserialize(serialization));
    return new OrderCollection(orders);
}
