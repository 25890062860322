import {useEffect, useState} from 'react';

export const useIsMobile = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    const handleWindowSizeChange = () => setWidth(window.innerWidth);
    const isMobile = () => width <= 768;

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return isMobile();
}
