import {Organization} from "../../domain/Organization";
import {createOrganization} from "../../state/organizationSlice";
import {useAppDispatch} from "../../../shared/hooks/stateHooks";
import {useNavigate} from "react-router-dom";
import {useUUID} from "../../../shared/hooks/useUUID";
import OrganizationForm from "../OrganizationForm/OrganizationForm";
import {useTranslate} from "../../../shared/hooks/useTranslate";

function CreateOrganization() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const uuid = useUUID();
    const {t, td} = useTranslate('organization', 'CreateOrganizationPage.CreateOrganization');

    const initialValues = {
        sector: '',
        name: '',
        address: '',
        taxId: ''
    };

    const onSubmit = async (values: any, actions: any) => {
        try {
            const organization: Organization = buildOrganization(values);
            const action = createOrganization({organization});
            await dispatch(action).unwrap();
            navigate(`/organizations/${organization.id}`);
        } catch {
            actions.setFieldError('taxId', 'Something went wrong. Try again later.')
        }
    };

    const buildOrganization = (formValues: any): Organization => {
        return {
            id: uuid,
            sector: formValues.sector as string,
            name: formValues.name as string,
            address: formValues.address as string,
            taxId: formValues.taxId as string,
            anyOrderCompleted: false,
            currency: 'EUR'
        }
    };

    return (
        <OrganizationForm
            cardTitle={t('create_organization')}
            submitLabel={td('action.create')}
            initialValues={initialValues}
            onSubmit={onSubmit}
        />
    )

}

export default CreateOrganization;