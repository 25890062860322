import {OrderMetadata} from "../OrderMetadata";
import {StripeOrderMetadata} from "../StripeOrderMetadata";

export class OrderMetadataBuilder {
    build(data: any): OrderMetadata | undefined {

        if (!data) {
            return;
        }

        if (data.provider === StripeOrderMetadata.provider()) {
            return new StripeOrderMetadata(data.definition, data.response);
        }

    }
}