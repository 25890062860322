import {EditOrganizationProps} from "./EditOrganizationProps";
import {Organization} from "../../domain/Organization";
import {editOrganization} from "../../state/organizationSlice";
import {useAppDispatch} from "../../../shared/hooks/stateHooks";
import {useNavigate} from "react-router-dom";
import OrganizationForm from "../OrganizationForm/OrganizationForm";
import {useTranslate} from "../../../shared/hooks/useTranslate";

function EditOrganization({organization}: EditOrganizationProps) {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {t, td} = useTranslate('organization', 'EditOrganizationPage.EditOrganization');

    const initialValues = {
        sector: organization.sector,
        name: organization.name,
        address: organization.address || '',
        taxId: organization.taxId || ''
    };

    const onSubmit = async (values: any, actions: any) => {

        try {
            const organization: Organization = buildOrganization(values);
            const action = editOrganization({organization});
            await dispatch(action).unwrap();
            navigate(`/organizations/${organization.id}`);
        } catch {
            actions.setFieldError('taxId', 'Something went wrong. Try again later.')
        }


    };

    const buildOrganization = (formValues: any): Organization => {
        return {
            ...organization!,
            sector: formValues.sector as string,
            name: formValues.name as string,
            address: formValues.address as string,
            taxId: formValues.taxId as string
        }
    };

    return (
        <OrganizationForm
            cardTitle={t('edit_organization')}
            submitLabel={td('action.save')}
            initialValues={initialValues}
            onSubmit={onSubmit}
        />
    )

}

export default EditOrganization;