import {useOrderList} from './useOrderList';
import {Order} from '../domain/Order';
import {OrderCollection} from "../domain/OrderCollection";

export const useOrderListByAforableId = (aforableId?: string): OrderCollection => {
    const orderList = useOrderList();
    const orders = orderList
        .toArray()
        .filter((order: Order) => order.aforableId === aforableId);
    return new OrderCollection(orders);
}
