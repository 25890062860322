import axios from 'axios';
import ApiAbstractRepository from "../../shared/repository/ApiAbstractRepository";
import {OfferBuilder} from "../domain/builder/OfferBuilder";
import {Offer} from "../domain/Offer";

export class ApiOfferRepository extends ApiAbstractRepository {

    private builder: OfferBuilder;

    constructor() {
        super();
        this.builder = new OfferBuilder();
    }

    async offersByAforable(aforableId: string): Promise<Offer[]> {

        const config = this.authConfig();

        return await axios
            .get(this.baseUrl + `/aforables/${aforableId}/offers`, config)
            .then(response => response.data.data.map((data: any) => this.builder.build(data, aforableId)))
            .catch(e => this.handleFailureRequest(e));
    }

}
