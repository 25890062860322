
import {
    Avatar, Box,
    Card,
    CardContent, Typography
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Translate} from '@mui/icons-material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useUserLogged} from '../../../auth/hooks/useUserLogged';
import {useAppDispatch} from '../../../shared/hooks/stateHooks';
import {switchLocale} from '../../state/userSlice';
import {useTranslate} from "../../../shared/hooks/useTranslate";

function SwitchLanguage() {

    const dispatch = useAppDispatch();
    const userLogged = useUserLogged();
    const { t, switchLanguage } = useTranslate('user', 'ProfilePage.SwitchLanguage'
    );

    const [locale, setLocale] = useState(userLogged?.locale || '');

    useEffect(() => {

        if (!userLogged?.locale) {
            return;
        }

        setLocale(userLogged.locale);
    }, [userLogged?.locale]);

    const handleSwitchLocale = (locale: string) => {

        if (!userLogged) {
            return;
        }

        switchLanguage(locale as any);
        dispatch(switchLocale({locale, userId: userLogged?.id}));
    }


    return (
        <Card>
            <CardContent sx={{pb: 0}}>
                <Typography component="div" variant="h5">{t('switch_language')}</Typography>
                <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                    <Avatar sx={{ bgcolor: 'primary.main', mr: 2}}>
                        <Translate />
                    </Avatar>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{t('language')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={locale}
                            label="Language"
                            onChange={(e) => handleSwitchLocale(e.target.value)}
                        >
                            <MenuItem value={'en'}>English</MenuItem>
                            <MenuItem value={'es'}>Español</MenuItem>
                            <MenuItem value={'ca'}>Català</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

            </CardContent>
        </Card>
    );
}

export default SwitchLanguage;
