import axios from 'axios';
import {OrderBuilder} from '../domain/builder/OrderBuilder';
import ApiAbstractRepository from "../../shared/repository/ApiAbstractRepository";
import {OrderResponse} from "../domain/to/OrderResponse";
import {AforableBuilder} from "../../aforable/domain/builder/aforableBuilder";

export class ApiOrderRepository extends ApiAbstractRepository {

    private builder: OrderBuilder;
    private aforableBuilder: AforableBuilder;

    constructor() {
        super();
        this.builder = new OrderBuilder();
        this.aforableBuilder = new AforableBuilder();
    }

    async ordersByOrganization(organizationId: string): Promise<OrderResponse> {

        const config = this.authConfig();

        return await axios
            .get(this.baseUrl + `/organizations/${organizationId}/orders`, config)
            .then(
                response => {
                    return {
                        orderList: response.data.data.map((data: any) => this.builder.build(data)),
                        aforableList: response.data.related.aforable.map((data: any) => this.aforableBuilder.execute(data)),
                    }
                }
            )
            .catch((e) => this.handleFailureRequest(e));
    }

    async ordersByAforable(aforableId: string): Promise<OrderResponse> {

        const config = this.authConfig();

        return await axios
            .get(this.baseUrl + `/aforables/${aforableId}/orders`, config)
            .then(
                response => {
                    return {
                        orderList: response.data.data.map((data: any) => this.builder.build(data)),
                        aforableList: response.data.related.aforable.map((data: any) => this.aforableBuilder.execute(data)),
                    }
                }
            )
            .catch((e) => this.handleFailureRequest(e));
    }

    async orderById(orderId: string): Promise<OrderResponse> {

        const config = this.authConfig();

        return await axios
            .get(this.baseUrl + `/orders/${orderId}`, config)
            .then(response => {
                return {
                    orderList: [this.builder.build(response.data.data)],
                    aforableList: response.data.related.aforable.map((data: any) => this.aforableBuilder.execute(data)),
                }
            })
            .catch( (e) => this.handleFailureRequest(e));
    }

    async createOrderAttempt(
        orderId: string,
        aforableId: string,
        offerId: string
    ): Promise<void> {
        const config = this.authConfig();

        const data = {
          id: orderId,
          aforableId,
          offerId
        };

        await axios.post(this.baseUrl + `/orders`, data, config )
            .then(() => {})
            .catch((e) => this.handleFailureRequest(e));
    }

    async completeOrder(orderId: string): Promise<void> {
        const config = this.authConfig();

        await axios.put(this.baseUrl + `/orders/${orderId}/completion`, {}, config )
            .then(() => {})
            .catch((e) => this.handleFailureRequest(e));
    }

}
