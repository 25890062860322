import {useTranslation} from "react-i18next";
import {useCallback, useRef} from "react";

interface TranslateInterface {
    t: (key: string, options?: any) => string,
    td: (key: string, options?: any) => string,
    switchLanguage: (language: availableLanguages) => void,
    language: string
}

type availableLanguages = 'en' | 'es' | 'ca';

export const useTranslate = (
    module?: string,
    defaultPrefix?: string
): TranslateInterface => {
    const { t, i18n } = useTranslation([ ...(module ? [module]: []), 'shared']);
    const i18nRef = useRef(i18n);

    const translate = (key: string, options?: any): string => {
        const prefix = defaultPrefix ? `${defaultPrefix}.` : '';
        return t(`${prefix}${key}`, options) as unknown as string;
    };

    const translateDefault = (key: string, options?: any): string => {
        return t(key, { ns: 'shared', ...options}) as unknown as string;
    };

    const switchLanguage = useCallback(
        (language: availableLanguages): void => {
            i18nRef.current.changeLanguage(language);
    }, [i18nRef]);

    const language = i18nRef.current.language;

    return {
        t: translate,
        td: translateDefault,
        switchLanguage,
        language
    }

};