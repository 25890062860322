import {
    Paper,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import {CheckBox, CheckBoxOutlineBlank} from '@mui/icons-material';
import {Order} from '../../domain/Order';
import {OrdersTableProps} from './OrdersTableProps';
import {useAforableMap} from "../../../aforable/hooks/useAforableMap";
import {useTranslate} from "../../../shared/hooks/useTranslate";

function OrdersTable(ordersTableProps: OrdersTableProps) {

    const aforableListById = useAforableMap();
    const { t, td, language } = useTranslate('order', 'Component.OrdersTable')

    const renderedRows = ordersTableProps.orders.map((order: Order) => (
        <TableRow key={order.id}>
            <TableCell align="center">{order.isActive ? <CheckBox color={'primary'}/>: <CheckBoxOutlineBlank/>}</TableCell>
            <TableCell align="center">{aforableListById[order.aforableId].name}</TableCell>
            <TableCell align="center">{td('format.price', {val: order.pricing.amount, currency: order.pricing.currency})}</TableCell>
            <TableCell align="center">{new Date(order.activationDateRange.startDate).toLocaleDateString(language)}</TableCell>
            <TableCell align="center">{new Date(order.activationDateRange.endDate).toLocaleDateString(language)}</TableCell>
            <TableCell align="center">{new Date(order.createdAt).toLocaleDateString(language)}</TableCell>

        </TableRow>
    ));

    return (
        <TableContainer component={Paper} elevation={0}>
            <Table sx={{ minWidth: 850 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">{t('active')}</TableCell>
                        <TableCell align="center">{t('space')}</TableCell>
                        <TableCell align="center">{t('price')}</TableCell>
                        <TableCell align="center">{t('service_start_date')}</TableCell>
                        <TableCell align="center">{t('service_end_date')}</TableCell>
                        <TableCell align="center">{t('date_of_purchase')}</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>{renderedRows}</TableBody>
            </Table>
        </TableContainer>
    );
}

export default OrdersTable;
