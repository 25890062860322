import React from 'react';
import PrivateLayout from '../../../shared/components/PrivateLayout/PrivateLayout';
import {Box, CircularProgress, Container} from '@mui/material';
import {useOrganizationById} from '../../hooks/useOrganizationById';
import {useParams} from 'react-router-dom';
import EditOrganization from "../../components/EditOrganization/EditOrganization";

function EditOrganizationPage() {

    const params = useParams();
    const organization = useOrganizationById(params.id);

    return (
        <PrivateLayout>
            <Container maxWidth={'sm'} sx={{px: 0}}>
                {organization ? (
                    <EditOrganization organization={organization}/>
                ): (
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress/>
                    </Box>
                )}
            </Container>
        </PrivateLayout>
    );
}

export default EditOrganizationPage;
