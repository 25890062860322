import {OrganizationFormProps} from './OrganizationFormProps';
import {Avatar, Box, Button, Card, CardActions, CardContent, FormControl, TextField, Typography} from '@mui/material';
import {
    AccountBalance,
    Business,
    BusinessCenter,
    Map,
    Save,
} from '@mui/icons-material';
import React, {useState} from 'react';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {useTranslate} from "../../../shared/hooks/useTranslate";

function OrganizationForm(props: OrganizationFormProps) {

    const [isSubmitDisabled, setSubmitDisabled] = useState(false);
    const { t } = useTranslate('organization', 'Component.OrganizationForm');

    const validationSchema = Yup.object({
        sector: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
    });

    const onSubmit = async (values: any, actions: any) => {
        setSubmitDisabled(true);
        await props.onSubmit(values, actions);
        setSubmitDisabled(false);
    };

    const formik = useFormik({
        initialValues: props.initialValues,
        validationSchema,
        onSubmit,
    });


    return (
        <Card>
            <form onSubmit={formik.handleSubmit}>
                <CardContent sx={{pb: 0}}>
                    <Typography component="div" variant="h5">{props.cardTitle}</Typography>

                    <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                        <Avatar sx={{bgcolor: 'primary.main', mr: 2}}>
                            <Business/>
                        </Avatar>
                        <FormControl fullWidth>
                            <TextField
                                id="name"
                                name="name"
                                label={`${t('name')} *`}
                                variant="outlined"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </FormControl>
                    </Box>

                    <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                        <Avatar sx={{ bgcolor: 'primary.main', mr: 2}}>
                            <BusinessCenter />
                        </Avatar>
                        <FormControl fullWidth>
                            <InputLabel id="sector">{`${t('sector')} *`}</InputLabel>
                            <Select
                                labelId="sector"
                                id="sector"
                                name="sector"
                                label={`${t('sector')} *`}
                                value={formik.values.sector}
                                onChange={formik.handleChange}
                                error={formik.touched.sector && Boolean(formik.errors.sector)}
                            >
                                <MenuItem value={'PUBLIC'}>{t('public')}</MenuItem>
                                <MenuItem value={'PRIVATE'}>{t('private')}</MenuItem>
                                <MenuItem value={'INDIVIDUAL'}>{t('individual')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>


                    <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                        <Avatar sx={{bgcolor: 'primary.main', mr: 2}}>
                            <Map/>
                        </Avatar>
                        <FormControl fullWidth>
                            <TextField
                                id="address"
                                name="address"
                                label={t('address')}
                                variant="outlined"
                                type="text"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                helperText={formik.touched.address && formik.errors.address}
                            />
                        </FormControl>
                    </Box>

                    <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                        <Avatar sx={{bgcolor: 'primary.main', mr: 2}}>
                            <AccountBalance/>
                        </Avatar>
                        <FormControl fullWidth>
                            <TextField
                                id="taxId"
                                name="taxId"
                                label={t('tax_id')}
                                variant="outlined"
                                type="text"
                                value={formik.values.taxId}
                                onChange={formik.handleChange}
                                error={formik.touched.taxId && Boolean(formik.errors.taxId)}
                                helperText={formik.touched.taxId && formik.errors.taxId}
                            />
                        </FormControl>
                    </Box>
                </CardContent>
                <CardActions sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button startIcon={<Save/>} color="primary" type="submit" disabled={isSubmitDisabled}>
                        {props.submitLabel}
                    </Button>
                </CardActions>
            </form>
        </Card>
    )
}

export default OrganizationForm;
