

export class AforableCapacity {

    constructor(
        private _maxAllowedCapacity: number,
        private _currentUsedCapacity: number
    ) {}


    get maxAllowedCapacity(): number {
        return this._maxAllowedCapacity;
    }

    get currentUsedCapacity(): number {
        return this._currentUsedCapacity;
    }

    increaseCurrentUsedCapacity(): AforableCapacity {
        return new AforableCapacity(
          this._maxAllowedCapacity,
          this._currentUsedCapacity + 1
        );
    }

    decreaseCurrentUsedCapacity(): AforableCapacity {
        return new AforableCapacity(
            this._maxAllowedCapacity,
            this._currentUsedCapacity - 1
        );
    }

    resetCurrentUsedCapacity(): AforableCapacity {
        return new AforableCapacity(
            this._maxAllowedCapacity,
            0
        );
    }

    usagePercentage(): number {
        const max = this.maxAllowedCapacity;
        const used = this.currentUsedCapacity;

        let percentage = (used * 100) / max;

        if (percentage > 100) {
            percentage = 100;
        }

        return Number(percentage.toFixed(2));
    }

    isCurrentUsedCapacityAtMinimum(): boolean {
        return this._currentUsedCapacity <= 0;
    }

    isCurrentUsedCapactyAtMaximum(): boolean {
        return this._currentUsedCapacity >= this._maxAllowedCapacity;
    }

    isMaxAllowedCapacityAboveFremiumLimit(): boolean {
        return this._maxAllowedCapacity > 50;
    }
}
