import {useParams} from 'react-router-dom';
import {useAforableById} from '../../hooks/useAforableById';
import {useAppDispatch} from '../../../shared/hooks/stateHooks';
import {useEffect} from 'react';
import {fetchAforableById} from '../../state/aforableSlice';
import PrivateLayout from '../../../shared/components/PrivateLayout/PrivateLayout';
import {Box, CircularProgress, Container} from '@mui/material';
import AforableDetail from '../../components/AforableDetail/AforableDetail';
import AforablePublicLink from '../../components/AforablePublicLink/AforablePublicLink';
import RemoveAforable from "../../components/RemoveAforable/RemoveAforable";
import {fetchOrdersByAforable} from "../../../order/state/orderSlice";


function AforableDetailPage() {

    const params = useParams();
    const aforableId = params.id!;
    const aforable = useAforableById(aforableId);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchAforableById({aforableId}));
        dispatch(fetchOrdersByAforable({aforableId}));
    }, [dispatch, aforableId]);

    return (
        <PrivateLayout>
            <Container maxWidth={'sm'} sx={{px: 0}}>
                { aforable ? (
                    <>
                        <AforableDetail aforable={aforable}/>
                        <Box sx={{m: 2}}/>
                        <AforablePublicLink aforable={aforable}/>
                        <Box sx={{m: 2}}/>
                        <RemoveAforable aforable={aforable}/>
                    </>
                ): (
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress/>
                    </Box>
                )}
            </Container>
        </PrivateLayout>
    );
}

export default AforableDetailPage;
