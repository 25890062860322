import React from 'react';
import AuthHeader from '../../shared/AuthHeader/AuthHeader';
import {useTranslate} from "../../../../shared/hooks/useTranslate";

function LogInHeader() {

    const { td } = useTranslate()

    return (
        <AuthHeader>{td('action.login')}</AuthHeader>
    );
}



export default LogInHeader;
