import {Avatar, Box, Button, Card, CardActions, CardContent, FormControl, TextField, Typography} from "@mui/material";
import React, {useRef} from "react";
import {AforablePublicLinkProps} from "./AforablePublicLinkProps";
import {ContentCopy, InsertLink, Share} from "@mui/icons-material";
import {useTranslate} from "../../../shared/hooks/useTranslate";

function AforablePublicLink({aforable}: AforablePublicLinkProps) {

    const linkInputRef = useRef(null);
    const { t, td } = useTranslate('aforable', 'AforableDetailPage.AforablePublicLink');


    const buildLink = (): string => {
        return `${window.location.origin}/s/${aforable.slug}`;
    };

    const copyLinkToClipboard = (): void => {
        const input = document.getElementById("public-link") as HTMLInputElement;

        if (!(input instanceof HTMLInputElement)) {
            return;
        }

        input.select();
        input.setSelectionRange(0, 99999);

        navigator.clipboard.writeText(input.value);
    }

    return (
        <Card>
            <CardContent sx={{pb: 0}}>
                <Typography component="div" variant="h5">{t('public_link')}</Typography>
                <Typography variant="body2" color="text.secondary">{t('public_link_sub')}</Typography>

                <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                    <Avatar sx={{bgcolor: 'primary.main', mr: 2}}>
                        <InsertLink/>
                    </Avatar>
                    <FormControl fullWidth>
                        <TextField
                            id="public-link"
                            name="public-link"
                            variant="outlined"
                            type="text"
                            InputProps={{
                                readOnly: true,
                            }}
                            value={buildLink()}
                            ref={linkInputRef}
                        />
                    </FormControl>
                </Box>
            </CardContent>
            <CardActions sx={{display: "flex", justifyContent: "flex-end", mt: 2}}>
                <Button startIcon={<ContentCopy/>} color="primary" onClick={copyLinkToClipboard}>
                    {td('action.copy')}
                </Button>
                <Button startIcon={<Share/>} color="primary" href={buildLink()} target="_blank">
                    {td('action.visit')}
                </Button>
            </CardActions>
        </Card>
    );
}

export default AforablePublicLink;