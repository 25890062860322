import {createAsyncThunk, createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {OrganizationMemberState} from './OrganizationMemberState';
import {OrganizationMember} from '../domain/OrganizationMember';
import {AuthState} from "../../auth/state/AuthState";
import {RootState} from "../../shared/state/store";
import {ApiOrganizationMemberRepository} from "../repository/ApiOrganizationMemberRepository";
import {addOrganizationList} from "../../organization/state/organizationSlice";


const initialState: OrganizationMemberState = { isPending: false, listById: {}, hasBeenFetched: false};
const ORGANIZATION_MEMBER_SLICE = 'organizationMember'

export const organizationMemberSlice = createSlice({
    name: ORGANIZATION_MEMBER_SLICE,
    initialState,
    reducers: {
        clearOrganizationMember: () => initialState,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchOrganizationMemberships.pending, (state: Draft<OrganizationMemberState>): OrganizationMemberState => {
                return { ...state, isPending: true };
            })
            .addCase(fetchOrganizationMemberships.fulfilled, (state: Draft<OrganizationMemberState>, action: PayloadAction<OrganizationMember[]>): OrganizationMemberState => {

                const newList = action.payload.reduce((acc: {[organizationMemberId: string]: OrganizationMember}, organizationMember: OrganizationMember) => {
                    acc[organizationMember.id] = organizationMember;
                    return acc;
                }, {});


                return {
                    ...state,
                    listById: {...state.listById, ...newList},
                    isPending: false,
                    hasBeenFetched: true
                }
            })
    }
});

export const fetchOrganizationMemberships = createAsyncThunk(
    `${ORGANIZATION_MEMBER_SLICE}/fetchOrganizationMemberships`,
    async (data: void, thunk): Promise<OrganizationMember[]> => {

        const auth: AuthState = (thunk.getState() as RootState).auth;

        if (!auth.token || !auth.userLoggedId) {
            return [];
        }

        const repository = new ApiOrganizationMemberRepository();
        const organizationResponse = await repository.organizationMemberships(auth.userLoggedId);

        thunk.dispatch(addOrganizationList(organizationResponse.organizationList));

        return organizationResponse.organizationMemberList;
    }
);

export const {clearOrganizationMember} = organizationMemberSlice.actions

export default organizationMemberSlice.reducer
