import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// USER translations
import user_en from '../../../user/translations/en.json';
import user_es from '../../../user/translations/es.json';
import user_ca from '../../../user/translations/ca.json';

// ORGANIZATION translations
import organization_en from '../../../organization/translations/en.json';
import organization_es from '../../../organization/translations/es.json';
import organization_ca from '../../../organization/translations/ca.json';

// ORGANIZATION_MEMBER translations
import organizationMember_en from '../../../organizationMember/translations/en.json';
import organizationMember_es from '../../../organizationMember/translations/es.json';
import organizationMember_ca from '../../../organizationMember/translations/ca.json';

// ORDER translations
import order_en from '../../../order/translations/en.json';
import order_es from '../../../order/translations/es.json';
import order_ca from '../../../order/translations/ca.json';

// AFORABLE translations
import aforable_en from '../../../aforable/translations/en.json';
import aforable_es from '../../../aforable/translations/es.json';
import aforable_ca from '../../../aforable/translations/ca.json';

// AUTH translations
import auth_en from '../../../auth/translations/en.json';
import auth_es from '../../../auth/translations/es.json';
import auth_ca from '../../../auth/translations/ca.json';

// SHARED translations
import shared_en from '../en.json';
import shared_es from '../es.json';
import shared_ca from '../ca.json';

const resources = {
    en: {
        user: user_en,
        organization: organization_en,
        organizationMember: organizationMember_en,
        order: order_en,
        aforable: aforable_en,
        auth: auth_en,
        shared: shared_en
    },
    es: {
        user: user_es,
        organization: organization_es,
        organizationMember: organizationMember_es,
        order: order_es,
        aforable: aforable_es,
        auth: auth_es,
        shared: shared_es
    },
    ca: {
        user: user_ca,
        organization: organization_ca,
        organizationMember: organizationMember_ca,
        order: order_ca,
        aforable: aforable_ca,
        auth: auth_ca,
        shared: shared_ca
    }
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'en',
        resources
    });