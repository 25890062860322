import TopAppBar from '../TopAppBar/TopAppBar';
import BottomAppBar from '../BottomAppBar/BottomAppBar';
import {useAuthSelector} from '../../../auth/hooks/useAuthSelector';
import {useIsMobile} from '../../hooks/useIsMobile';

function AppBarFactory() {

    const auth = useAuthSelector();
    const isMobile = useIsMobile();


    if (auth.isPending) {
        return <></>;
    }

    if (!auth.isLoggedIn) {
        return <></>;
    }

    if (isMobile) {
        return <BottomAppBar/>;
    }

    return <TopAppBar/>;
}



export default AppBarFactory;
