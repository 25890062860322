import {Collection} from "../../shared/domain/Collection";
import {Offer} from "./Offer";

export class OfferCollection extends Collection<Offer>{

    findById(offerId?: string): Offer | undefined {
        for (let offer of this.items) {

            if (offer.id === offerId) {
                return offer;
            }

        }
    }
}