import {createAsyncThunk, createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {OfferState} from "./OfferState";
import {OfferSerializer} from "./serialization/offerSerializer";
import {ApiOfferRepository} from "../repository/ApiOfferRepository";
import {Offer} from "../domain/Offer";
import {OfferSerialization} from "./serialization/OfferSerialization";


const initialState: OfferState = { isPending: false, listById: {}};
const OFFER_SLICE = 'offer';
const serializer = new OfferSerializer();

export const orderSlice = createSlice({
    name: OFFER_SLICE,
    initialState,
    reducers: {
        clearOffer: () => initialState,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchOffersByAforable.pending, (state: Draft<OfferState>): OfferState => {
                return { ...state, isPending: true };
            })
            .addCase(fetchOffersByAforable.fulfilled, (state: Draft<OfferState>, action: PayloadAction<OfferSerialization[]>): OfferState => {

                const newList = action.payload.reduce((acc: {[offerId: string]: OfferSerialization}, offer: OfferSerialization) => {
                    acc[offer.id] = offer;
                    return acc;
                }, {});


                return {
                    ...state,
                    listById: {...state.listById, ...newList},
                    isPending: false,
                }
            })
    }
});

export const fetchOffersByAforable = createAsyncThunk(
    `${OFFER_SLICE}/fetchOffersByAforable`,
    async (data: {aforableId: string}, thunk): Promise<OfferSerialization[]> => {
        const repository = new ApiOfferRepository();
        const offers = await repository.offersByAforable(data.aforableId);
        return offers.map((offer: Offer) => serializer.serialize(offer));
    }
);

export const {clearOffer} = orderSlice.actions

export default orderSlice.reducer
