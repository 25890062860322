
export class OfferDuration {
    constructor(
        private _amount: number,
        private _timeUnit: string
    ) {}


    get amount(): number {
        return this._amount;
    }

    get timeUnit(): string {
        return this._timeUnit;
    }
}