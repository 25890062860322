import FacebookLoginButtonProps from "./FacebookLoginButtonProps";
import {Button} from "@mui/material";
import {FacebookRounded} from "@mui/icons-material";

function FacebookLoginButton(props: FacebookLoginButtonProps) {

    return (
        <Button
            disableElevation
            onClick={props.onLoginResponseCallback}
            sx={{
                width: '100%',
                maxWidth: '300px',
                height: '40px'
            }}
            style={{
                textTransform: 'none',
                backgroundColor: '#1877f2',
                height: '40px !important',
                color: 'white',
                justifyContent: 'initial'
            }}
        >
            <FacebookRounded sx={{ fontSize: 30 }} style={{marginLeft: '-2px'}}/>
            <div
                style={{
                    width: '100%',
                    fontSize: '16.5px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    letterSpacing: '0.001em',
                    fontWeight: 'bolder'
                }}>
                {props.buttonLabel}
            </div>

        </Button>
    );

}

export default FacebookLoginButton;