import {OfferPricing} from "./OfferPricing";
import {OfferDuration} from "./OfferDuration";

export class Offer {

    constructor(
        private _id: string,
        private _aforableId: string,
        private _pricing: OfferPricing,
        private _duration: OfferDuration,
        private _isFreeTrial: boolean
    ) {}


    get id(): string {
        return this._id;
    }

    get aforableId(): string {
        return this._aforableId;
    }

    get pricing(): OfferPricing {
        return this._pricing;
    }

    get duration(): OfferDuration {
        return this._duration;
    }

    get isFreeTrial(): boolean {
        return this._isFreeTrial;
    }
}