import {
    Avatar, Button,
    Card,
    CardActions,
    CardContent,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from '@mui/material';
import React from 'react';
import {AccountBalance, Business, BusinessCenter, Edit, Map} from '@mui/icons-material';
import {useActiveOrganization} from '../../hooks/useActiveOrganization';
import {Link} from 'react-router-dom';
import {useTranslate} from "../../../shared/hooks/useTranslate";

function OrganizationDetail() {

    const activeOrganization = useActiveOrganization();
    const { t, td } = useTranslate('organization', 'OrganizationPage.OrganizationDetail')

    if (!activeOrganization) {
        return <></>;
    }

    return (
        <Card>
            <CardContent sx={{pb: 0}}>
                <Typography component="div" variant="h5">{t('organization_detail')}</Typography>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: 'primary.main'}}>
                                <Business />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={t('organization_name')} secondary={activeOrganization.name} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: 'primary.main'}}>
                                <BusinessCenter />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={t('organization_sector')} secondary={t(activeOrganization.sector.toLowerCase())} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: 'primary.main'}}>
                                <Map />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={t('organization_address')} secondary={activeOrganization.address || '-'} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: 'primary.main'}}>
                                <AccountBalance />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={t('organization_tax_id')} secondary={activeOrganization.taxId || '-'} />
                    </ListItem>
                </List>
            </CardContent>
            <CardActions sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button startIcon={<Edit/>} color="primary" component={Link} to={`/organizations/${activeOrganization.id}/edit`}>
                    {td('action.edit')}
                </Button>
            </CardActions>
        </Card>
    );
}

export default OrganizationDetail;
