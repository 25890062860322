import {useAppDispatch} from "../../../shared/hooks/stateHooks";
import {useNavigate} from "react-router-dom";
import {useUUID} from "../../../shared/hooks/useUUID";
import {Aforable} from "../../domain/Aforable";
import {createAforable} from "../../state/aforableSlice";
import {AforableType} from "../../domain/AforableType";
import {AforableCapacity} from "../../domain/AforableCapacity";
import {CreateAforableProps} from "./CreateAforableProps";
import React from "react";
import CreateAforableForm from "../CreateAforableForm/CreateAforableForm";

function CreateAforable({organization}: CreateAforableProps) {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const uuid = useUUID();

    const onSubmit = async (values: any) => {
        const aforable: Aforable = buildAforable(values);
        const action = createAforable({aforable});
        await dispatch(action).unwrap();
        navigate(`/spaces/${aforable.id}/detail`);
    };

    const buildAforable = (formValues: any): Aforable => {

        return new Aforable(
            uuid,
            formValues.name as string,
            formValues.slug as string, // TODO: This should be calculated in backend!
            AforableType.buildFromString(formValues.type as string),
            new AforableCapacity(
                formValues.maxAllowedCapacity as number,
                0
            ),
            organization!.id,
            new Date(),
            new Date(),
            formValues.website as string,
        );

    };


    return (<CreateAforableForm onSubmit={onSubmit}/>);
}

export default CreateAforable;