import {
    Avatar, Button,
    Card,
    CardActions,
    CardContent,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from '@mui/material';
import {
    BusinessCenter,
    Edit,
    Exposure,
    Home,
    InsertLink, Language,
    Star
} from '@mui/icons-material';
import {Link} from 'react-router-dom';
import React from 'react';
import {AforableDetailProps} from './AforableDetailProps';
import {useOrder} from "../../../order/hooks/useOrder";
import {useOrderListByAforableId} from "../../../order/hooks/useOrderListByAforableId";
import {useTranslate} from "../../../shared/hooks/useTranslate";


function AforableDetail({aforable}: AforableDetailProps) {

    const orderState = useOrder();
    const aforableOrderCollection = useOrderListByAforableId(aforable.id);
    const { t, td } = useTranslate('aforable', 'AforableDetailPage.AforableDetail');

    const premiumLabel = () => {

        if (orderState.isPending) {
            return (<span>-</span>);
        }

        if (aforableOrderCollection.hasAnyActiveOrder()) {
            return (<span>{td('info.yes')}</span>);
        }

        if (aforableOrderCollection.isPremiumExpired()) {
            return (<span>{t('expired')}. <Link to={`/spaces/${aforable.id}/upgrade-premium`}>{td('action.renew')}</Link></span>);
        }

        return (<span>{td('info.no')}</span>);
    };

    return (
        <Card>
            <CardContent sx={{pb: 0}}>
                <Typography component="div" variant="h5">{t('space_detail')}</Typography>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: 'primary.main'}}>
                                <Home />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText sx={{textTransform: 'capitalize'}} primary={t('name')} secondary={aforable.name} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: 'primary.main'}}>
                                <BusinessCenter />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={t('type')} secondary={aforable.type.label} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: 'primary.main'}}>
                                <Exposure />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={t('max_allowed_capacity')} secondary={aforable.capacity.maxAllowedCapacity} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: 'primary.main'}}>
                                <InsertLink />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={t('slug')} secondary={aforable.slug} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: 'primary.main'}}>
                                <Language />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={t('website')} secondary={aforable.website || '-'} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: 'primary.main'}}>
                                <Star />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={t('premium')} secondary={premiumLabel()} />
                    </ListItem>
                </List>
            </CardContent>
            <CardActions sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button startIcon={<Edit/>} color="primary" component={Link} to={`/spaces/${aforable.id}/edit`}>
                    {td('action.edit')}
                </Button>
            </CardActions>
        </Card>
    );
}

export default AforableDetail;
