import {OrganizationMember} from '../domain/OrganizationMember';
import {useOrganizationMemberList} from './useOrganizationMemberList';

export const useOrganizationMemberListByUserId = (userId?: string): OrganizationMember[] => {
    const organizationMemberList = useOrganizationMemberList();

    return organizationMemberList.filter(
        (organizationMember: OrganizationMember) => organizationMember.userId === userId
    );
}
