import {Aforable} from '../domain/Aforable';
import {useAforableList} from './useAforableList';

export const useAforableListByOrganizationId = (organizationId?: string): Aforable[] => {
    const aforableList = useAforableList();

    if (!organizationId) {
        return [];
    }
    return aforableList.filter(
        (aforable: Aforable) => aforable.organizationId === organizationId
    );
}
