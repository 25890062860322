import {Avatar} from '@mui/material';
import React from 'react';
import {AforableCapacityBaseControlProps} from "./AforableCapacityBaseControlProps";

const BUTTON_SIZE: number = 85;
const ICON_SIZE: number = BUTTON_SIZE * 0.80;
export const ICON_SX_PROPERTIES = {fontSize: ICON_SIZE};

function AforableCapacityBaseControl(props: AforableCapacityBaseControlProps) {

    const buttonSizeProperties = {width: BUTTON_SIZE, height: BUTTON_SIZE};

    return (
        <Avatar
            sx={{...buttonSizeProperties, bgcolor: props.isDisabled ? 'neutral': 'primary.main'}}
            style={{cursor: props.isDisabled ? 'initial': 'pointer'}}
            onClick={props.isDisabled ? () => {} : props.onClick}
        >
            {props.icon}
        </Avatar>
    );
}

export default AforableCapacityBaseControl;
