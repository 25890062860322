import {
    Box, Button,
    Card,
    CardContent,
    Typography
} from '@mui/material';
import React from 'react';
import {AforableUpgradePremiumSuccessProps} from "./AforableUpgradePremiumSuccessProps";
import {CheckCircleOutline, FormatListBulleted, ForwardToInbox} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";
import {useTranslate} from "../../../shared/hooks/useTranslate";


function AforableUpgradePremiumSuccess(props: AforableUpgradePremiumSuccessProps) {

    const aforable = props.aforable;

    const navigate = useNavigate();
    const { t } = useTranslate('order', 'AforableUpgradePremiumSuccessPage.AforableUpgradePremiumSuccess');

    return (
        <Card>
            <CardContent sx={{pb: 0}}>
                <Box sx={{display: "flex", justifyContent: 'center', mb: 2}}>
                    <CheckCircleOutline color="success" sx={{fontSize: 75}}/>
                </Box>
                <Typography component="div" variant="h3" align="center">{t('thank_you')}.</Typography>
                <Typography
                    component="div"
                    variant="h5"
                    align="center"
                    sx={{mb: 2}}
                >
                    {t('order_completed')}!
                </Typography>

                <Box sx={{display: "flex", alignItems: 'center', mb: 1}}>
                    <ForwardToInbox sx={{mr: 1}}/>
                    <Typography component="div" variant="body2">{t('email_sent')}.</Typography>
                </Box>

                <Box sx={{display: "flex", alignItems: 'center', mb: 1}}>
                    <FormatListBulleted sx={{mr: 1}}/>
                    <Typography component="div" variant="body2">{t('history')} <Link to={`/organizations/${aforable.organizationId}`}>{t('organization_section')}</Link>.</Typography>
                </Box>

                <Box sx={{display: "flex", justifyContent: 'center', mt: 2, mb: 1}}>
                    <Button
                        variant="text"
                        onClick={() => navigate(`/spaces/${aforable.id}/detail`)}
                    >
                        {t('return_space_detail')}
                    </Button>
                </Box>

            </CardContent>
        </Card>
    );
}

export default AforableUpgradePremiumSuccess;
