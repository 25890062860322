import {
    Avatar, Button,
    Card,
    CardActions,
    CardContent,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from '@mui/material';
import React from 'react';
import {AlternateEmail, BadgeOutlined, Edit, Person} from '@mui/icons-material';
import {useUserLogged} from '../../../auth/hooks/useUserLogged';
import {Link} from 'react-router-dom';
import {useTranslate} from "../../../shared/hooks/useTranslate";

function ProfileDetail() {

    const userLogged = useUserLogged();
    const { t, td } = useTranslate(
        'user',
        'ProfilePage.ProfileDetail'
    );

    return (
        <Card>
            <CardContent sx={{pb: 0}}>
                <Typography component="div" variant="h5">{t('your_account')}</Typography>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: 'primary.main'}}>
                                <Person />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText sx={{textTransform: 'capitalize'}} primary={t('name')} secondary={userLogged?.firstName} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: 'primary.main'}}>
                                <BadgeOutlined />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText sx={{textTransform: 'capitalize'}} primary={t('last_name')} secondary={userLogged?.lastName} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: 'primary.main'}}>
                                <AlternateEmail />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={t('email')} secondary={userLogged?.email} />
                    </ListItem>
                </List>
            </CardContent>
            <CardActions sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button startIcon={<Edit/>} color="primary" component={Link} to="/profile/edit">
                    {td('action.edit')}
                </Button>
            </CardActions>
        </Card>
    );
}

export default ProfileDetail;
