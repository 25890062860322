import {useAuthSelector} from '../../../auth/hooks/useAuthSelector';
import {useAppDispatch} from '../../hooks/stateHooks';
import {useNavigate} from 'react-router-dom';
import React, {useEffect} from 'react';
import {getAuthStatus} from '../../../auth/state/authSlice';
import {Container} from '@mui/material';
import {useIsMobile} from '../../hooks/useIsMobile';
import {useOrganization} from '../../../organization/hooks/useOrganization';
import {useUserLogged} from '../../../auth/hooks/useUserLogged';
import {fetchUserLogged} from '../../../user/state/userSlice';
import {useIsUserLoggedPartOfAnOrganization} from "../../../organizationMember/hooks/useIsUserLoggedPartOfAnOrganization";
import {fetchOrganizationMemberships} from "../../../organizationMember/state/organizationMemberSlice";
import {useTranslate} from "../../hooks/useTranslate";

function PrivateLayout(props: any) {

    const auth = useAuthSelector();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const userLogged = useUserLogged();
    const { switchLanguage } = useTranslate();

    const organizationsState = useOrganization();
    const isUserPartOfAnOrganization = useIsUserLoggedPartOfAnOrganization();

    useEffect(() => {
        dispatch(getAuthStatus());
    }, [dispatch]);

    useEffect(() => {

        if (auth.isPending) {
            return;
        }

        if (auth.isLoggedIn) {
            return;
        }

        navigate('/')
    }, [navigate, auth]);

    useEffect(() => {

        if (!userLogged) {
            return;
        }

        if (!isUserPartOfAnOrganization) {
            return;
        }

        if (organizationsState.isPending) {
            return;
        }

        if (organizationsState.activeOrganizationId) {
            return;
        }

        switchLanguage(userLogged.locale as any);
        dispatch(fetchOrganizationMemberships());

    }, [organizationsState, dispatch, isUserPartOfAnOrganization, userLogged, switchLanguage])

    useEffect(() => {
        if (!auth.userLoggedId) {
            return;
        }

        if (userLogged) {
            return;
        }

        dispatch(fetchUserLogged());

    }, [auth.userLoggedId, dispatch, userLogged]);


    const marginTop = isMobile ? 5: 11;
    const paddingHorizontal = isMobile ? 1: 2;

    return (
        <Container maxWidth="lg" sx={{mt: marginTop, px: paddingHorizontal, mb: 11}} >
            {props.children}
        </Container>
    );
}



export default PrivateLayout;
