import PrivateLayout from '../../../shared/components/PrivateLayout/PrivateLayout';
import AforableList from '../../components/AforableList/AforableList';
import {useEffect} from 'react';
import {fetchAforables} from '../../state/aforableSlice';
import {useAppDispatch} from '../../../shared/hooks/stateHooks';
import {Box, CircularProgress} from '@mui/material';
import {useAforableListByOrganizationId} from '../../hooks/useAforableListByOrganizationId';
import {useActiveOrganization} from '../../../organization/hooks/useActiveOrganization';
import {useAforable} from '../../hooks/useAforable';
import FirstOrganizationCreationDialog
    from "../../../shared/components/FirstOrganizationCreationDialog/FirstOrganizationCreationDialog";
import {
    useIsUserLoggedPartOfAnOrganization
} from "../../../organizationMember/hooks/useIsUserLoggedPartOfAnOrganization";
import {useOrganizationMember} from "../../../organizationMember/hooks/useOrganizationMember";

function AforableListPage() {

    const dispatch = useAppDispatch();

    const aforableState = useAforable();
    const organizationMemberState = useOrganizationMember();

    const activeOrganization = useActiveOrganization();
    const isUserPartOfAnOrganization = useIsUserLoggedPartOfAnOrganization();
    const aforableList = useAforableListByOrganizationId(activeOrganization?.id);

    const isContentLoaded = (): boolean => {

        if (!organizationMemberState.hasBeenFetched) {
            return false;
        }

        if (!isUserPartOfAnOrganization) {
            return true;
        }

        if (aforableList.length) {
            return true;
        }

        return !aforableState.isPending;
    };

    useEffect(() => {

        if (!activeOrganization) {
            return;
        }

        dispatch(fetchAforables());
    }, [dispatch, activeOrganization]);



    return (
        <PrivateLayout>
            { isContentLoaded() ? (
                <AforableList aforables={aforableList}/>
            ): (
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress/>
                </Box>
            )}
            <FirstOrganizationCreationDialog/>
        </PrivateLayout>
    );
}

export default AforableListPage;
