import React, {ReactElement} from "react";
import types, {DEFAULT_AFORABLE_TYPE} from "./AforableTypeList";

export class AforableType {

    constructor(
        private _id: string,
        private _label: string,
        private _icon: ReactElement
    ) {}


    static buildFromString(id: string): AforableType {

        for (const type of types){

            if (type.id === id) {
                return new AforableType(type.id, type.label, type.icon);
            }
        }

        return AforableType.buildFromString(DEFAULT_AFORABLE_TYPE);
    }


    get id(): string {
        return this._id;
    }

    get label(): string {
        return this._label;
    }

    get icon(): React.ReactElement {
        return this._icon;
    }
}
