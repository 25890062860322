import {
    Box,
    Button,
    CircularProgress, FormControl,
} from '@mui/material';
import React from 'react';
import * as Yup from "yup";
import {useFormik} from "formik";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Offer} from "../../../offer/domain/Offer";
import {Aforable} from "../../../aforable/domain/Aforable";
import {AforableUpgradePremiumFormProps} from "./AforableUpgradePremiumFormProps";
import {useTranslate} from "../../../shared/hooks/useTranslate";


function AforableUpgradePremiumForm(props: AforableUpgradePremiumFormProps) {

    const {selectedAforable, aforables, offers, isLoading, onSubmit} = props;
    const { t, td } = useTranslate('order', 'AforableUpgradePremiumPage.AforableUpgradePremiumForm');

    const initialValues = {
        aforable: props.selectedAforable?.id || aforables[0].id,
        offer: props.offers.first()?.id
    };

    const validationSchema = Yup.object({
        aforable: Yup.string().required('Required'),
        offer: Yup.string().required('Required')
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    });

    const aforableOptions = aforables.map((aforable: Aforable) => {
        return <MenuItem value={aforable.id} key={aforable.id}>{aforable.name}</MenuItem>;
    });

    const planOptions = offers.toArray().map((offer: Offer) => {
        return <MenuItem value={offer.id} key={offer.id}>{t(`offer_${offer.duration.timeUnit}`, {count: offer.duration.amount})}</MenuItem>;
    });

    const purchaseButtonLabel = () => {
        const currentOfferId = formik.values.offer;
        const offer = offers.findById(currentOfferId);

        const baseLabel = t('purchase');

        if (!offer) {
            return baseLabel;
        }

        if (offer.isFreeTrial) {
            return t('start_free_trial');
        }

        const pricing = td('format.price', {val: offer.pricing.amount, currency: offer.pricing.currency});
        return `${baseLabel} (${pricing})`;
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Box sx={{pt: 3}}>
                    <FormControl fullWidth>
                        <InputLabel id="aforable">{t('space')} *</InputLabel>
                        <Select
                            labelId="aforable"
                            id="aforable"
                            name="aforable"
                            label={`${t('space')} *`}
                            disabled={!!selectedAforable}
                            value={formik.values.aforable}
                            onChange={formik.handleChange}
                            error={formik.touched.aforable && Boolean(formik.errors.aforable)}
                        >
                            {aforableOptions}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{pt: 2}}>
                    <FormControl fullWidth sx={{pm: 1}}>
                        <InputLabel id="offer">{t('plan')} *</InputLabel>
                        <Select
                            labelId="offer"
                            id="offer"
                            name="offer"
                            label={`${t('plan')} *`}
                            value={formik.values.offer}
                            onChange={formik.handleChange}
                            error={formik.touched.offer && Boolean(formik.errors.offer)}
                        >
                            {planOptions}
                        </Select>
                    </FormControl>
                </Box>

                <Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                        disabled={isLoading}
                    >
                        {isLoading && <CircularProgress size={25}/>}
                        {!isLoading && purchaseButtonLabel()}
                    </Button>
                </Box>
            </form>
        </>
    );
}

export default AforableUpgradePremiumForm;
