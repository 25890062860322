import React, {useState} from 'react';
import AuthLayout from '../../components/shared/AuthLayout/AuthLayout';
import ResetPasswordHeader from "../../components/ResetPassword/ResetPasswordHeader/ResetPasswordHeader";
import ResetPasswordForm from "../../components/ResetPassword/ResetPasswordForm/ResetPasswordForm";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch} from "../../../shared/hooks/stateHooks";
import InfoDialog from "../../../shared/components/InfoDialog/InfoDialog";
import {resetPassword} from "../../state/authSlice";

function ResetPasswordPage() {

    const navigate = useNavigate();
    const params = useParams();
    const token = params.token as string;

    const dispatch = useAppDispatch();
    const [inProgress, setInProgress] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    const onResetPasswordSubmit = async (values: any, actions: any): Promise<void> => {
        setInProgress(true);

        const password = values.password as string

        try {
            await dispatch(resetPassword({token, password})).unwrap();
            setModalOpen(true);
        } catch (error) {
            actions.setFieldError('passwordConfirmation', 'Something went wrong. Try again later.')
        }

        setInProgress(false);
    };

    const onSuccessModalClosed = () => {
        setModalOpen(false);
        navigate(`/login`);
    }

    return (
        <AuthLayout>
            <ResetPasswordHeader/>
            <ResetPasswordForm onSubmit={onResetPasswordSubmit} inProgress={inProgress}/>
            <InfoDialog
                open={isModalOpen}
                title="Success!"
                body="The password has been reset! You can login into your account now."
                buttonLabel="Got it"
                onClose={onSuccessModalClosed}
            />
        </AuthLayout>
    );
}

export default ResetPasswordPage;
