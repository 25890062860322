import Link from "../../../../shared/components/Link/Link";
import React from "react";
import {useTranslate} from "../../../../shared/hooks/useTranslate";

function AlreadyHaveAnAccountLink() {

    const { t } = useTranslate('auth', 'Component.AlreadyHaveAnAccountLink')

    return (
        <Link to="/login" variant="body2" color="primary">
            {t('already_have_an_account_login')}
        </Link>
    );
}

export default AlreadyHaveAnAccountLink;