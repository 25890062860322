import {Aforable} from "../../domain/Aforable";
import {AforableSerialization} from "./AforableSerialization";
import {AforableType} from "../../domain/AforableType";
import {AforableCapacity} from "../../domain/AforableCapacity";

export class AforableSerializer {

    serialize(aforable: Aforable): AforableSerialization {
        return {
            id: aforable.id,
            name: aforable.name,
            slug: aforable.slug,
            type: aforable.type.id,
            capacity: {
                maxAllowedCapacity: aforable.capacity.maxAllowedCapacity,
                currentUsedCapacity: aforable.capacity.currentUsedCapacity
            },
            website: aforable.website,
            organizationId: aforable.organizationId,
            createdAt: aforable.createdAt.getTime(),
            updatedAt: aforable.updatedAt.getTime(),
        }
    }

    deserialize(serialization: AforableSerialization): Aforable {

        return new Aforable(
            serialization.id,
            serialization.name,
            serialization.slug,
            AforableType.buildFromString(serialization.type),
            new AforableCapacity(serialization.capacity.maxAllowedCapacity, serialization.capacity.currentUsedCapacity),
            serialization.organizationId,
            new Date(serialization.createdAt),
            new Date(serialization.updatedAt),
            serialization.website
        );

    }
}