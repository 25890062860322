import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ActionConfirmationDialog from "../ActionConfirmationDialog/ActionConfirmationDialog";
import ExpiredAforablePremiumDialogProps from "./ExpiredAforablePremiumDialogProps";
import {useOrderListByAforableId} from "../../../order/hooks/useOrderListByAforableId";
import {useAppDispatch} from "../../hooks/stateHooks";
import {fetchOrdersByAforable} from "../../../order/state/orderSlice";
import {useTranslate} from "../../hooks/useTranslate";

function ExpiredAforablePremiumDialog(props: ExpiredAforablePremiumDialogProps) {

    const orderCollection = useOrderListByAforableId(props.aforableId);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t, td } = useTranslate('shared', 'Component.ExpiredAforablePremiumDialog');

    const [isModalOpen, setModalOpen] = useState(false);

    const onUpgrade = () => {
        setModalOpen(false);
        navigate(`/spaces/${props.aforableId}/upgrade-premium`);
    }

    useEffect(() => {
        setModalOpen(orderCollection.isPremiumExpired());
    }, [orderCollection]);

    useEffect( () => {
        dispatch(fetchOrdersByAforable({aforableId: props.aforableId}));
    }, [dispatch, props.aforableId]);

    return (
        <ActionConfirmationDialog
            open={isModalOpen}
            title={t('premium_subscription_expired')}
            body={t('premium_subscription_expired_sub')}
            dismissButtonLabel={td('action.dismiss')}
            confirmationButtonLabel={td('action.upgrade')}
            onDismiss={props.onDismiss}
            onConfirm={onUpgrade}
        />
    );
}

export default ExpiredAforablePremiumDialog;
