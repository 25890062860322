import React from 'react';
import AuthHeader from '../../shared/AuthHeader/AuthHeader';

function ResetPasswordHeader() {
    return (
        <AuthHeader>Reset Password</AuthHeader>
    );
}



export default ResetPasswordHeader;
