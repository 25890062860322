import {createAsyncThunk, createSlice, Draft, PayloadAction} from '@reduxjs/toolkit'
import {AuthState} from './AuthState';
import {LocalStorageAuthRepository} from '../repository/LocalStorageAuthRepository';
import {ApiAuthRepository} from '../repository/ApiAuthRepository';
import {clearOrganizations} from '../../organization/state/organizationSlice';
import {clearAforables} from '../../aforable/state/aforableSlice';
import {addUserLogged, clearUser} from '../../user/state/userSlice';
import {clearOrganizationMember} from '../../organizationMember/state/organizationMemberSlice';
import {clearOrder} from '../../order/state/orderSlice';
import {LoginResponse} from "../domain/to/LoginResponse";
import {AuthResponse} from "../domain/to/AuthResponse";

const localStorageRepository = new LocalStorageAuthRepository();
const apiRepository = new ApiAuthRepository();

const initialState: AuthState = { isPending: true};
const loggedOutState: AuthState = { isLoggedIn: false, isPending: false };
const AUTH_SLICE = 'auth'

export const authSlice = createSlice({
    name: AUTH_SLICE,
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getAuthStatus.fulfilled, (state: Draft<AuthState>, action: PayloadAction<AuthState>): AuthState => {
                return action.payload;
            })
            .addCase(loginWithEmail.pending, (): AuthState => {
                return {isLoggedIn: false, isPending: true};
            })
            .addCase(loginWithEmail.fulfilled, (state: Draft<AuthState>, action: PayloadAction<AuthResponse>): AuthState => {
                return action.payload.auth;
            })
            .addCase(loginWithEmail.rejected, (state: Draft<AuthState>, action): AuthState => {
                return action.payload as AuthState;
            })
            .addCase(loginWithGoogle.pending, (): AuthState => {
                return {isLoggedIn: false, isPending: true};
            })
            .addCase(loginWithGoogle.fulfilled, (state: Draft<AuthState>, action: PayloadAction<AuthResponse>): AuthState => {
                return action.payload.auth;
            })
            .addCase(loginWithGoogle.rejected, (state: Draft<AuthState>, action): AuthState => {
                const payload = action.payload as AuthResponse;
                return payload.auth;
            })
            .addCase(loginWithFacebook.pending, (): AuthState => {
                return {isLoggedIn: false, isPending: true};
            })
            .addCase(loginWithFacebook.fulfilled, (state: Draft<AuthState>, action: PayloadAction<AuthResponse>): AuthState => {
                return action.payload.auth;
            })
            .addCase(loginWithFacebook.rejected, (state: Draft<AuthState>, action): AuthState => {
                const payload = action.payload as AuthResponse;
                return payload.auth;
            })
            .addCase(logout.pending, (): AuthState => {
                return loggedOutState;
            })
            .addCase(logout.fulfilled, (): AuthState => {
                return loggedOutState;
            })
    }
});


export const getAuthStatus = createAsyncThunk(`${AUTH_SLICE}/getAuthStatus`, (): AuthState => {
    return localStorageRepository.authStatus();
});

function buildSuccessAuthState(loginResponse: LoginResponse) {
    return {
        isLoggedIn: true,
        loggedInTimestamp: new Date().getTime(),
        token: loginResponse.token,
        userLoggedId: loginResponse.userLogged.id,
        isPending: false
    };
}

function handleSuccessLoginAttempt(thunk: any, loginResponse: LoginResponse): AuthResponse {
    thunk.dispatch(addUserLogged(loginResponse!.userLogged));

    const auth = buildSuccessAuthState(loginResponse);
    localStorageRepository.setAuthStatus(auth);

    return {
        auth: auth,
        hasFailed: false
    };
}

export const loginWithEmail = createAsyncThunk(
    `${AUTH_SLICE}/loginWithEmail`,
    async (credentials: {email: string, password: string}, thunk): Promise<AuthResponse> => {

        try {
            const loginResponse = await apiRepository.loginWithEmail(credentials.email, credentials.password);
            return handleSuccessLoginAttempt(thunk, loginResponse);
        } catch (e) {
            return handleFailedLoginAttempt(thunk, e);
        }


    }
);

export const loginWithGoogle = createAsyncThunk(
    `${AUTH_SLICE}/loginWithGoogle`,
    async (credentials: {googleToken: string}, thunk): Promise<AuthResponse> => {
        try {
            const loginResponse = await apiRepository.loginWithGoogle(credentials.googleToken);
            return handleSuccessLoginAttempt(thunk, loginResponse);
        } catch (e: any) {
            return handleFailedLoginAttempt(thunk, e);
        }
    }
);

export const loginWithFacebook = createAsyncThunk(
    `${AUTH_SLICE}/loginWithFacebook`,
    async (credentials: {facebookToken: string}, thunk): Promise<AuthResponse> => {
        try {
            const loginResponse = await apiRepository.loginWithFacebook(credentials.facebookToken);
            return handleSuccessLoginAttempt(thunk, loginResponse);
        } catch (e: any) {
            return handleFailedLoginAttempt(thunk, e);
        }
    }
);

export const sendPasswordRecoveryMail = createAsyncThunk(
    `${AUTH_SLICE}/sendPasswordRecoveryMail`,
    async (data: {email: string}): Promise<void> => {
            await apiRepository.sendPasswordRecoveryMail(data.email);
    }
);

export const resetPassword = createAsyncThunk(
    `${AUTH_SLICE}/resetPassword`,
    async (data: {token: string, password: string}): Promise<void> => {
        await apiRepository.resetPassword(data.token, data.password);
    }
);

export const logout = createAsyncThunk(`${AUTH_SLICE}/logout`, (data, thunk): void => {

    localStorageRepository.setAuthStatus(loggedOutState);

    thunk.dispatch(clearOrder());
    thunk.dispatch(clearAforables());
    thunk.dispatch(clearOrganizations());
    thunk.dispatch(clearOrganizationMember());
    thunk.dispatch(clearUser());

});

function handleFailedLoginAttempt(thunk: any, e: any): AuthResponse {
    const failedAuth: AuthState = {isPending: false, isLoggedIn: false};
    localStorageRepository.setAuthStatus(failedAuth);

    const authResponse: AuthResponse = {
        auth: failedAuth,
        hasFailed: true,
        errorCode: e.name
    };

    return thunk.rejectWithValue(authResponse) as AuthResponse;
};

export const {} = authSlice.actions

export default authSlice.reducer
