import {useOrderList} from './useOrderList';
import {Order} from '../domain/Order';
import {useAforableListByOrganizationId} from '../../aforable/hooks/useAforableListByOrganizationId';
import {Aforable} from '../../aforable/domain/Aforable';
import {OrderCollection} from "../domain/OrderCollection";

export const useOrderListByOrganizationId = (organizationId?: string): OrderCollection => {
    const orderList = useOrderList();
    const aforableList = useAforableListByOrganizationId(organizationId);

    const aforableIdList = aforableList.map((aforable: Aforable) => aforable.id);
    const orders = orderList
        .toArray()
        .filter((order: Order) => aforableIdList.includes(order.aforableId));
    return new OrderCollection(orders);
}
