import {Avatar, Typography} from '@mui/material';
import React from 'react';
import AuthHeaderProps from './AuthHeaderProps';

function AuthHeader(props: AuthHeaderProps) {
    return (
        <>
            <Avatar
                sx={{ m: 1, bgcolor: 'white', width: 80, height: 80 }}
                src={`${process.env.PUBLIC_URL}/img/logo/aforable_logo_no_bg.png`}/>
            <Typography component="h1" variant="h5">
                {props.children}
            </Typography>
        </>
    );
}



export default AuthHeader;
