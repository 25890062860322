import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './shared/components/App/App';
import {store} from './shared/state/store';
import {Provider} from 'react-redux';
import dayjs from "dayjs";
import 'dayjs/locale/ca';
import 'dayjs/locale/es';
import relativeTime from 'dayjs/plugin/relativeTime';
import "./shared/translations/i18n/i18n";

dayjs.extend(relativeTime)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <Provider store={store}>
      <App/>
      </Provider>
  </React.StrictMode>
);

