import {BottomNavigation, BottomNavigationAction, Paper} from "@mui/material";
import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {options} from './options';
import {currentOptionFinder} from '../AppBarFactory/currentOptionFinder';
import {NavigationOption} from './NavigationOption';
import {useActiveOrganization} from '../../../organization/hooks/useActiveOrganization';
import {useTranslate} from "../../hooks/useTranslate";

function BottomAppBar() {

    const location = useLocation();
    const { td } = useTranslate()

    const activeOrganization = useActiveOrganization();

    const optionList = options(activeOrganization?.id);

    useEffect(() => {
        const optionList = options(activeOrganization?.id);
        setOption(currentOptionFinder(location.pathname, optionList, optionList[1]) as NavigationOption);
    }, [location.pathname, activeOrganization])

    const [selectedOption, setOption] = useState(currentOptionFinder(location.pathname, optionList, optionList[1]) as NavigationOption);

    const renderedOptions = optionList.map((option: NavigationOption) => {
        return (
            <BottomNavigationAction
                component={Link}
                to={option.path}
                label={td(`section.${option.label}`)}
                icon={option.icon}
                key={option.path}
                onClick={() => setOption(option)}
            />
        )
    });

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 2 }} elevation={3}>
            <BottomNavigation
                showLabels={false}
                value={selectedOption.position}
            >
                {renderedOptions}
            </BottomNavigation>
        </Paper>
    );
}



export default BottomAppBar;
