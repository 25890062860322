
import React from 'react';
import {Button, Container} from '@mui/material';
import {useAppDispatch} from '../../../shared/hooks/stateHooks';
import {logout} from '../../../auth/state/authSlice';
import {useTranslate} from "../../../shared/hooks/useTranslate";

function Logout() {

    const dispatch = useAppDispatch();
    const { td } = useTranslate();

    const logoutHandler = () => dispatch(logout());

    return (
        <Container sx={{display: "flex", justifyContent: "center"}}>
            <Button variant="contained" sx={{ margin: 1, width: "90%", maxWidth: 300 }} onClick={logoutHandler}>
                {td('action.logout')}
            </Button>
        </Container>
    );
}

export default Logout;
