
export class Collection<T> {

    protected items: T[] = [];

    constructor(items: T[]) {
        this.items = items;
    }

    public add(item: T): void {
        this.items.push(item);
    }

    public first(): T | undefined {

        if (this.isEmpty()) {
            return;
        }

        return this.items[0];
    }

    public last(): T | undefined {

        if (this.isEmpty()) {
            return;
        }

        return this.items[this.items.length - 1];
    }

    public count(): number {
        return this.items.length;
    }

    public isEmpty(): boolean {
        return !this.count();
    }

    public toArray(): T[] {
        return this.items;
    }

}