import {EditAforableFormProps} from "./EditAforableFormProps";
import {AforableCapacity} from "../../domain/AforableCapacity";
import * as Yup from "yup";
import React, {useState} from "react";
import {useFormik} from "formik";
import AforableFormBase from "../AforableFormBase/AforableFormBase";
import AforableUpgradePremiumDialog
    from "../../../shared/components/AforableUpgradePremiumDialog/AforableUpgradePremiumDialog";
import {Avatar, Box, FormControl, TextField} from "@mui/material";
import {Exposure, InsertLink} from "@mui/icons-material";
import {useTranslate} from "../../../shared/hooks/useTranslate";

function EditAforableForm(props: EditAforableFormProps) {

    const { t, td } = useTranslate('aforable')

    const {aforable} = props;
    const [isSubmitDisabled, setSubmitDisabled] = useState(false);
    const [isPremiumFeatureRequested, setPremiumFeatureRequested] = useState(false);

    const initialValues = {
        name: aforable.name,
        type: aforable.type.id,
        maxAllowedCapacity: aforable.capacity.maxAllowedCapacity,
        slug: aforable.slug,
        website: aforable.website || '',
    };

    const validationSchema = Yup.object({
        name: Yup.string().required(td('validation.required')),
        type: Yup.string().required(td('validation.required')),
        maxAllowedCapacity: Yup.number().min(1, td('validation.min_characters', {count: 1})).required(td('validation.required')),
        slug: Yup.string().required(td('validation.required')).matches(/^[a-z\d]+(-?[a-z\d]+)*$/i, td('action.slug')),
    });

    const onSubmit = async (values: any) => {
        setSubmitDisabled(true);
        await props.onSubmit(values);
        setSubmitDisabled(false);
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    const onUpgradePremiumDialogDismiss = () => {
        setPremiumFeatureRequested(false);
        formik.setFieldValue('maxAllowedCapacity', initialValues.maxAllowedCapacity)
        formik.setFieldValue('slug', initialValues.slug)
    };

    const onMaxAllowedCapacityChange = (e: React.ChangeEvent<any>): void => {
        formik.handleChange(e);

        const newMaxAllowedCapacity = e.target.value;

        const aforableCapacity = new AforableCapacity(
            newMaxAllowedCapacity,
            aforable!.capacity.currentUsedCapacity
        );

        if (aforableCapacity.isMaxAllowedCapacityAboveFremiumLimit()) {
            setPremiumFeatureRequested(true);
        }

    };

    const onSlugChange = (e: React.ChangeEvent<any>): void => {
        formik.handleChange(e);
        setPremiumFeatureRequested(true);
    };

    const renderMaxAllowedCapacityFormField = () => {

        return (
            <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                <Avatar sx={{bgcolor: 'primary.main', mr: 2}}>
                    <Exposure/>
                </Avatar>
                <FormControl fullWidth>
                    <TextField
                        id="maxAllowedCapacity"
                        name="maxAllowedCapacity"
                        label={`${t('Component.AforableFormBase.max_allowed_capacity')} *`}
                        variant="outlined"
                        type="number"
                        value={formik.values.maxAllowedCapacity}
                        onChange={onMaxAllowedCapacityChange}
                        error={formik.touched.maxAllowedCapacity && Boolean(formik.errors.maxAllowedCapacity)}
                        helperText={formik.errors.maxAllowedCapacity && formik.touched.maxAllowedCapacity && String(formik.errors.maxAllowedCapacity)}
                    />
                </FormControl>
            </Box>
        );
    }

    const renderSlugFormField = () => {

        return (
            <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                <Avatar sx={{bgcolor: 'primary.main', mr: 2}}>
                    <InsertLink/>
                </Avatar>
                <FormControl fullWidth>
                    <TextField
                        id="slug"
                        name="slug"
                        label={`${t('Component.AforableFormBase.slug')} *`}
                        variant="outlined"
                        type="text"
                        value={formik.values.slug}
                        onChange={onSlugChange}
                        error={formik.touched.slug && Boolean(formik.errors.slug)}
                        helperText={formik.errors.slug && formik.touched.slug && String(formik.errors.slug)}
                    />
                </FormControl>
            </Box>
        );
    }

    return (
        <>
            <AforableFormBase
              formik={formik}
              cardTitle={t('EditAforablePage.EditAforableForm.edit_space')}
              submitLabel={td('action.save', {count: 6})}
              isSubmitDisabled={isSubmitDisabled}
              slugFormField={renderSlugFormField()}
              maxAllowedCapacityFormField={renderMaxAllowedCapacityFormField()}
            />
            <AforableUpgradePremiumDialog
                aforable={aforable}
                isPremiumFeatureRequested={isPremiumFeatureRequested}
                onDismiss={onUpgradePremiumDialogDismiss}
            />
        </>
    );

}

export default EditAforableForm;