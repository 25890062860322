import {
    Avatar,
    Box,
    Button, Divider, Drawer,
    List,
    ListItemAvatar, ListItemButton,
    ListItemText,
    Typography
} from '@mui/material';
import {Add, Business, CheckCircle, KeyboardArrowDown} from '@mui/icons-material';
import {useState} from 'react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import {Organization} from '../../domain/Organization';
import {useIsMobile} from '../../../shared/hooks/useIsMobile';
import {useScrollbarWidth} from '../../../shared/hooks/useScrollBarWith';
import {useOrganizationList} from '../../hooks/useOrganizationList';
import {useActiveOrganization} from '../../hooks/useActiveOrganization';
import {Link, useNavigate} from 'react-router-dom';
import {useTranslate} from "../../../shared/hooks/useTranslate";

function OrganizationSelector() {

    const organizationList = useOrganizationList();
    const activeOrganization = useActiveOrganization();
    const navigate = useNavigate();
    const { t } = useTranslate('organization', 'OrganizationPage.OrganizationSelector');

    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useIsMobile();
    const scrollBarWidth = useScrollbarWidth();

    const Puller = styled(Box)(({ theme }) => ({
        width: 30,
        height: 6,
        backgroundColor: theme.palette.mode === 'light' ? grey[500] : grey[900],
        borderRadius: 3,
        position: 'absolute',
        top: 8,
        left: 'calc(50% - 15px)',
    }));

    const selectOrganization = (organizationId: string) => {
        navigate(`/organizations/${organizationId}`);
        setIsOpen(false);
    };

    const renderedOrganizationList = organizationList.map((organization: Organization) => {
        return (
            <ListItemButton key={organization.id} sx={{ pt: 2, pb: 2 }} onClick={() => selectOrganization(organization.id)}>
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: 'primary.main', width: 55, height: 55}}>
                            <Business sx={{ fontSize: 35 }}/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText disableTypography sx={{ml: 1}}>
                        <Typography>{organization.name}</Typography>
                    </ListItemText>
                    {organization.id === activeOrganization?.id && <CheckCircle color="primary"/>}
            </ListItemButton>
        );
    })

    const scrollBarWidthCorrector = isMobile ? 0: scrollBarWidth;

    return (
        <Box sx={{display: "flex", justifyContent: "center"}}>
            <Button variant="outlined" startIcon={<KeyboardArrowDown/>} onClick={() => setIsOpen(true)}>
                {activeOrganization?.name}
            </Button>

            <Drawer
                container={window.document.body}
                anchor="bottom"
                open={isOpen}
                onClose={() => setIsOpen(false)}
                PaperProps={{ square: false, sx: { borderTopLeftRadius: "16px", borderTopRightRadius: "16px", maxWidth: 550, margin: 'auto', right: scrollBarWidthCorrector} }}
            >
                <Box>
                    <Puller />
                    <Box sx={{mt: 3, p: 0}} justifyContent="center">
                        <Typography align="center" variant="h6" sx={{fontWeight: 'bold'}}>{t('switch_organization')}</Typography>
                        <Divider sx={{mt: 1}}/>
                        <List dense={true} sx={{pt: 0, pb: 0}}>
                            {renderedOrganizationList}
                            <ListItemButton sx={{ pt: 2, pb: 2}} component={Link} to="/organizations/new">
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: 'primary.main', width: 55, height: 55}}>
                                        <Add sx={{ fontSize: 35 }}/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText disableTypography sx={{ml: 1}}>
                                    <Typography>{t('new_organization')}</Typography>
                                </ListItemText>
                            </ListItemButton>
                        </List>
                    </Box>

                </Box>
            </Drawer>
        </Box>
    );
}

export default OrganizationSelector;
