import {useOrganizationMemberListByUserId} from "./useOrganizationMemberListByUserId";
import {useOrganizationMember} from "./useOrganizationMember";
import {useUserLogged} from "../../auth/hooks/useUserLogged";

export const useIsUserLoggedPartOfAnOrganization = (): boolean => {

    const userLogged = useUserLogged();
    const organizationMember = useOrganizationMember();

    const organizationMemberList = useOrganizationMemberListByUserId(userLogged?.id);

    if (!organizationMember.hasBeenFetched) {
        return true;
    }

    return !!organizationMemberList.length;


}
