import {createAsyncThunk, createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {OrganizationState} from './OrganizationState';
import {ApiOrganizationRepository} from '../repository/ApiOrganizationRepository';
import {Organization} from '../domain/Organization';
import {fetchOrganizationMemberships} from '../../organizationMember/state/organizationMemberSlice';


const initialState: OrganizationState = { isPending: false, listById: {}};
const ORGANIZATION_SLICE = 'organization'

export const organizationSlice = createSlice({
    name: ORGANIZATION_SLICE,
    initialState,
    reducers: {
        clearOrganizations: () => initialState,
        selectActiveOrganization: (state: Draft<OrganizationState>, action: PayloadAction<string>) => {
            return {...state, activeOrganizationId: action.payload};
        },
        addOrganizationList: (state: Draft<OrganizationState>, action: PayloadAction<Organization[]>) => {

            const organizations = action.payload;

            const newList = organizations.reduce((acc: {[organizationId: string]: Organization}, organization: Organization) => {
                acc[organization.id] = organization;
                return acc;
            }, {});

            return {
                ...state,
                listById: {...state.listById, ...newList},
                isPending: false,
                activeOrganizationId: state.activeOrganizationId || organizations[0]?.id
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(editOrganization.fulfilled, (state: Draft<OrganizationState>, action: PayloadAction<Organization>): OrganizationState => {
                return {
                    ...state,
                    listById: {...state.listById, [action.payload.id]: action.payload},
                    activeOrganizationId: action.payload.id,
                    isPending: false,
                }
            })
            .addCase(createOrganization.fulfilled, (state: Draft<OrganizationState>, action: PayloadAction<Organization>): OrganizationState => {
                return {
                    ...state,
                    listById: {...state.listById, [action.payload.id]: action.payload},
                    activeOrganizationId: action.payload.id,
                    isPending: false,
                }
            })
    }
});

export const editOrganization = createAsyncThunk(
    `${ORGANIZATION_SLICE}/editOrganization`,
    async (data: {organization: Organization}): Promise<Organization> => {

        const repository = new ApiOrganizationRepository();
        await repository.editOrganization(data.organization);

        return data.organization;
    }
);

export const createOrganization = createAsyncThunk(
    `${ORGANIZATION_SLICE}/createOrganization`,
    async (data: {organization: Organization}, thunk): Promise<Organization> => {

        const repository = new ApiOrganizationRepository();
        await repository.createOrganization(data.organization);

        await thunk.dispatch(fetchOrganizationMemberships());

        return data.organization;
    }
);


export const {clearOrganizations, selectActiveOrganization, addOrganizationList} = organizationSlice.actions

export default organizationSlice.reducer
