import React from 'react';
import LogInHeader from '../../components/LogIn/LogInHeader/LogInHeader';
import LogInEmailForm from '../../components/LogIn/Methods/Email/LogInEmailForm/LogInEmailForm';
import LogInThirdParty from '../../components/LogIn/LogInThirdParty/LogInThirdParty';
import AuthLayout from '../../components/shared/AuthLayout/AuthLayout';
import {useAuthSelector} from '../../hooks/useAuthSelector';
import LogInDivider from "../../components/LogIn/LogInDivider/LogInDivider";

function LogInPage() {

    const auth = useAuthSelector();

    return (
        <AuthLayout>
            <LogInHeader/>
            <LogInEmailForm auth={auth}/>
            <LogInDivider/>
            <LogInThirdParty/>
        </AuthLayout>
    );
}

export default LogInPage;
