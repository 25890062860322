import {RootState} from '../../../shared/state/store';
import {User} from '../../domain/User';

export const userByIdSelector = (state: RootState, userId?: string): User | undefined => {

    if (!userId) {
        return;
    }

    return state.user.listById[userId];
}
