import axios, {AxiosResponse} from 'axios';
import {LoginResponse} from '../domain/to/LoginResponse';
import ApiAbstractRepository from '../../shared/repository/ApiAbstractRepository';

export class ApiAuthRepository extends ApiAbstractRepository {

    async loginWithEmail(email: string, password: string): Promise<LoginResponse> {

        const authData = {
            auth: {
                username: email,
                password: password
            }
        };

        return await axios
            .get(this.baseUrl + '/auth/email', authData)
            .then( response => this.handleSuccessAuthResponse(response) as any)
            .catch( e => this.handleFailureRequest(e));
    }

    async loginWithGoogle(token: string): Promise<LoginResponse> {

        return await axios
            .post(this.baseUrl + '/auth/google', { token })
            .then( response => this.handleSuccessAuthResponse(response) as any)
            .catch( e => this.handleFailureRequest(e));
    }

    async loginWithFacebook(token: string): Promise<LoginResponse> {

        return await axios
            .post(this.baseUrl + '/auth/facebook', { token })
            .then( response => this.handleSuccessAuthResponse(response) as any)
            .catch( e => this.handleFailureRequest(e));
    }

    async sendPasswordRecoveryMail(email: string): Promise<void> {
        await axios
            .post(this.baseUrl + '/forgot-password', {email})
            .catch( e => this.handleFailureRequest(e));
    }

    async resetPassword(token: string, password: string): Promise<void> {
        await axios
            .post(this.baseUrl + '/reset-password', {token, password})
            .catch( e => this.handleFailureRequest(e));
    }

    private handleSuccessAuthResponse = (response: AxiosResponse): LoginResponse  => {

        const data = response.data.data;

        return {
            token: data.token,
            userLogged: data.user
        }
    }

}
