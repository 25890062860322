import axios from 'axios';
import {Aforable} from '../domain/Aforable';
import ApiAbstractRepository from '../../shared/repository/ApiAbstractRepository';
import {AforableBuilder} from "../domain/builder/aforableBuilder";

export class ApiAforableRepository extends ApiAbstractRepository {

    private builder: AforableBuilder;

    constructor() {
        super();
        this.builder = new AforableBuilder();
    }

    async aforables(organizationId: string): Promise<Aforable[]> {

        const config = this.authConfig();

        return await axios
            .get(this.baseUrl + `/organizations/${organizationId}/aforables`, config)
            .then( response => response.data.data.map((obj: any) => this.builder.execute(obj)))
            .catch(e => this.handleFailureRequest(e));
    }

    async aforableById(aforableId: string): Promise<Aforable> {
        return await axios
            .get(this.baseUrl + `/aforables/${aforableId}`)
            .then( response => this.builder.execute(response.data.data) )
            .catch( e => this.handleFailureRequest(e) );
    }

    async aforableBySlug(slug: string): Promise<Aforable> {
        return await axios
            .get(this.baseUrl + `/aforables/${slug}?slug`)
            .then( response => this.builder.execute(response.data.data) )
            .catch( e => this.handleFailureRequest(e) );
    }

    async editAforable(aforable: Aforable): Promise<void> {

        const config = this.authConfig();

        const data = {
            name: aforable.name,
            type: aforable.type.id,
            maxAllowedCapacity: aforable.capacity.maxAllowedCapacity,
            website: aforable.website,
            slug: aforable.slug
        };

        return await axios
            .put(this.baseUrl + `/aforables/${aforable.id}`, data, config )
            .then(() => {})
            .catch((e) => this.handleFailureRequest(e));
    }

    async createAforable(aforable: Aforable): Promise<void> {
        const config = this.authConfig();

        const data = {
            id: aforable.id,
            name: aforable.name,
            type: aforable.type.id,
            slug: aforable.slug,
            organizationId: aforable.organizationId
        };

        return await axios
            .post(this.baseUrl + `/aforables`, data, config )
            .then(() => {})
            .catch((e) => this.handleFailureRequest(e));
    }

    async removeAforable(aforable: Aforable): Promise<void> {

        const config = this.authConfig();

        return await axios
            .delete(this.baseUrl + `/aforables/${aforable.id}`, config )
            .then(() => {})
            .catch((e) => this.handleFailureRequest(e));
    }

    async increaseAforableUsedCapacity(aforable: Aforable): Promise<void> {

        const config = this.authConfig();

        return await axios
            .post(this.baseUrl + `/aforables/${aforable.id}/used-capacity/increase`, {}, config )
            .then(() => {})
            .catch((e) => this.handleFailureRequest(e));

    }

    async decreaseAforableUsedCapacity(aforable: Aforable): Promise<void> {

        const config = this.authConfig();

        return await axios
            .post(this.baseUrl + `/aforables/${aforable.id}/used-capacity/decrease`, {}, config )
            .then(() => {})
            .catch((e) => this.handleFailureRequest(e));

    }

    async resetAforableUsedCapacity(aforable: Aforable): Promise<void> {

        const config = this.authConfig();

        const data = {
            "usedCapacity": 0
        };

        return await axios
            .post(this.baseUrl + `/aforables/${aforable.id}/used-capacity`, data, config )
            .then(() => {})
            .catch((e) => this.handleFailureRequest(e));

    }


}
