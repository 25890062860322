
class ApiError extends Error {
    get status(): number {
        return this._status;
    }

    private readonly _status: number;

    constructor(status: number, errorCode: string, errorDescription: string) {
        super(errorDescription);
        this.name = errorCode;
        this._status = status;
        Object.setPrototypeOf(this, ApiError.prototype);
    }


}

export default ApiError;
