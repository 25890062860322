import {FacebookClientCouldNotBeLoaded} from "../domain/error/FacebookClientCouldNotBeLoaded";
import {PrePopulatedUserData} from "../domain/to/PrePopulatedUserData";

export class FacebookLoginServiceRepository {

    private readonly appId: string;
    private facebook: any;
    private setUpCompleted: boolean;

    constructor(appId: string) {
        this.appId = appId;
        this.setUpCompleted = false;
        this.init();
    }

    private init(): void {

        if (this.isAlreadyInitialized() && this.isSetUpCompleted()) {
            return;
        }

        this.facebook = (window as any).FB;

        if (!this.isAlreadyInitialized()) {
            throw new FacebookClientCouldNotBeLoaded();
        }

        this.facebook.init({
            appId            : this.appId,
            autoLogAppEvents : true,
            xfbml            : true,
            version          : 'v13.0'
        });

        this.markSetUpAsCompleted();

    }

    private isAlreadyInitialized(): boolean {
        return !!this.facebook;
    }

    private isSetUpCompleted(): boolean {
        return this.setUpCompleted;
    }

    private markSetUpAsCompleted(): void
    {
        this.setUpCompleted = true;
    }

    public status(callback: (response: any) => void): void {
        this.facebook.getLoginStatus(callback);
    }

    public login(callback: (response: any) => void): void {
        this.facebook.login(
            callback,
            {scope: 'public_profile,email'}
        );
    }

    public userData(callback: (userData: PrePopulatedUserData) => void): void {
        this.facebook.api(
            `/me?fields=email,first_name,last_name,id`,
            (response: any) => {
                callback({
                    email: response.email,
                    firstName: response.first_name,
                    lastName: response.last_name,
                    thirdPartyId: response.id,
                    source: 'FACEBOOK',
                });
            },
            [
                {scope: 'email'},
                {scope: 'public_profile'},
            ]
        );
    }


}