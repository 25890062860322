import {Box, Typography} from '@mui/material';
import {useTranslate} from "../../hooks/useTranslate";


function LanguageSelector() {

    const {language, switchLanguage} = useTranslate();

    const getStyle = (controlLanguage: string): any => {

        const style = {
            marginLeft: '5px',
            marginRight: '5px',
            cursor: 'pointer', textDecoration: 'underline'
        };

        if (language === controlLanguage) {
            Object.assign(style, {fontWeight: 'bold', textDecoration: 'none'})
        }

        return style;

    };

    return (
        <Box sx={{width: '40%', marginLeft: 'auto', marginRight: 'auto'}}>
            <Typography
                variant="body2"
                color="text.secondary"
                sx={{display: 'flex', justifyContent: 'center'}}
            >
                <span onClick={() => switchLanguage('en')} style={getStyle('en')}>English</span>
                <span onClick={() => switchLanguage('es')} style={getStyle('es')}>Español</span>
                <span onClick={() => switchLanguage('ca')} style={getStyle('ca')}>Català</span>

            </Typography>
        </Box>

    );
}



export default LanguageSelector;
