import {Home, Person, Groups} from '@mui/icons-material';
import {NavigationOption} from './NavigationOption';


export function options(organizationId?: string): NavigationOption[] {
    return [
        {
            position: 0,
            path: `/organizations/${organizationId}`,
            label: 'organization',
            icon: <Groups/>
        },
        {
            position: 1,
            path: '/spaces',
            label: 'spaces',
            icon: <Home />
        },
        {
            position: 2,
            path: '/profile',
            label: 'profile',
            icon: <Person />
        }
    ];
}
