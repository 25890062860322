import React from 'react';
import ForgotPasswordHeader from '../../components/ForgotPassword/ForgotPasswordHeader/ForgotPasswordHeader';
import ForgotPasswordEmailForm from '../../components/ForgotPassword/ForgotPasswordEmailForm/ForgotPasswordEmailForm';
import AuthLayout from '../../components/shared/AuthLayout/AuthLayout';

function ForgotPasswordPage() {

    return (
        <AuthLayout>
            <ForgotPasswordHeader/>
            <ForgotPasswordEmailForm/>
        </AuthLayout>
    );
}

export default ForgotPasswordPage;
