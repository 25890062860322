import {
    Box,
    Card,
    CardContent, CircularProgress, Container, Typography
} from '@mui/material';
import React from 'react';
import {useOrderListByOrganizationId} from '../../hooks/useOrderListByOrganizationId';
import {useActiveOrganization} from '../../../organization/hooks/useActiveOrganization';
import OrdersTable from '../OrdersTable/OrdersTable';
import {Inbox} from '@mui/icons-material';
import {useOrder} from "../../hooks/useOrder";
import {useTranslate} from "../../../shared/hooks/useTranslate";

function OrganizationOrders() {

    const orderState = useOrder();
    const activeOrganization = useActiveOrganization();
    const orderCollection = useOrderListByOrganizationId(activeOrganization?.id);
    const { t, td } = useTranslate('order', 'Component.OrganizationOrders');

    const renderLoadingOrders = () => {
        return (
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <CircularProgress/>
            </Box>
        );
    }

    const renderEmptyOrderList = () => {
        return (
            <Container sx={{mt: 2}}>
                <Container sx={{display: 'flex', justifyContent: 'center'}}>
                    <Inbox sx={{fontSize: 50}}/>
                </Container>
                <Container sx={{display: 'flex', justifyContent: 'center'}}>
                    <Typography component="div" variant="h6">{td('info.no_data')}</Typography>
                </Container>
            </Container>
        )
    }

    const renderOrdersContent = () => {

        if (!orderCollection.isEmpty()) {
            return (
                <OrdersTable orders={orderCollection.toArray()}/>
            );
        }

        if (orderState.isPending) {
            return renderLoadingOrders();
        }

        return renderEmptyOrderList();

    }

    return (
        <Card>
            <CardContent sx={{pb: 0}}>
                <Typography component="div" variant="h5">{t('organization_purchases')}</Typography>
                {renderOrdersContent()}
            </CardContent>
        </Card>
    );
}

export default OrganizationOrders;
