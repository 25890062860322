import {createAsyncThunk, createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {OrderState} from './OrderState';
import {Order} from '../domain/Order';
import {ApiOrderRepository} from '../repository/ApiOrderRepository';
import {OrderSerializer} from "./serialization/orderSerializer";
import {OrderSerialization} from "./serialization/OrderSerialization";
import {addAforables} from "../../aforable/state/aforableSlice";
import {AforableSerializer} from "../../aforable/state/serialization/aforableSerializer";
import {Aforable} from "../../aforable/domain/Aforable";


const initialState: OrderState = { isPending: false, listById: {}};
const ORDER_SLICE = 'order';
const serializer = new OrderSerializer();
const aforableSerializer = new AforableSerializer();

export const orderSlice = createSlice({
    name: ORDER_SLICE,
    initialState,
    reducers: {
        clearOrder: () => initialState,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchOrdersByOrganization.pending, (state: Draft<OrderState>): OrderState => {
                return { ...state, isPending: true };
            })
            .addCase(fetchOrdersByOrganization.fulfilled, (state: Draft<OrderState>, action: PayloadAction<OrderSerialization[]>): OrderState => {

                const newList = action.payload.reduce((acc: {[orderId: string]: OrderSerialization}, order: OrderSerialization) => {
                    acc[order.id] = order;
                    return acc;
                }, {});


                return {
                    ...state,
                    listById: {...state.listById, ...newList},
                    isPending: false,
                }
            })
            .addCase(fetchOrdersByAforable.pending, (state: Draft<OrderState>): OrderState => {
                return { ...state, isPending: true };
            })
            .addCase(fetchOrdersByAforable.fulfilled, (state: Draft<OrderState>, action: PayloadAction<OrderSerialization[]>): OrderState => {

                const newList = action.payload.reduce((acc: {[orderId: string]: OrderSerialization}, order: OrderSerialization) => {
                    acc[order.id] = order;
                    return acc;
                }, {});


                return {
                    ...state,
                    listById: {...state.listById, ...newList},
                    isPending: false,
                }
            })
            .addCase(fetchOrderById.fulfilled, (state: Draft<OrderState>, action: PayloadAction<OrderSerialization[]>): OrderState => {

                const newList = action.payload.reduce((acc: {[orderId: string]: OrderSerialization}, order: OrderSerialization) => {
                    acc[order.id] = order;
                    return acc;
                }, {});


                return {
                    ...state,
                    listById: {...state.listById, ...newList},
                    isPending: false,
                }
            })
    }
});


export const fetchOrdersByOrganization = createAsyncThunk(
    `${ORDER_SLICE}/fetchOrganizationOrders`,
    async (data: {organizationId: string}, thunk): Promise<OrderSerialization[]> => {
        const repository = new ApiOrderRepository();
        const orderResponse = await repository.ordersByOrganization(data.organizationId);
        thunk.dispatch(addAforables(orderResponse.aforableList.map((aforable: Aforable) => aforableSerializer.serialize(aforable))));
        return orderResponse.orderList.map((order: Order) => serializer.serialize(order));
    }
);

export const fetchOrdersByAforable = createAsyncThunk(
    `${ORDER_SLICE}/fetchAforableOrders`,
    async (data: {aforableId: string}, thunk): Promise<OrderSerialization[]> => {
        const repository = new ApiOrderRepository();
        const orderResponse = await repository.ordersByAforable(data.aforableId);
        thunk.dispatch(addAforables(orderResponse.aforableList.map((aforable: Aforable) => aforableSerializer.serialize(aforable))));
        return orderResponse.orderList.map((order: Order) => serializer.serialize(order));
    }
);

export const fetchOrderById = createAsyncThunk(
    `${ORDER_SLICE}/fetchOrderById`,
    async (data: {orderId: string}, thunk): Promise<OrderSerialization[]> => {
        const repository = new ApiOrderRepository();
        const orderResponse = await repository.orderById(data.orderId);
        thunk.dispatch(addAforables(orderResponse.aforableList.map((aforable: Aforable) => aforableSerializer.serialize(aforable))));
        return orderResponse.orderList.map((order: Order) => serializer.serialize(order));
    }
);

export const createOrderAttempt = createAsyncThunk(
    `${ORDER_SLICE}/createOrderAttempt`,
    async (data: {orderId: string, aforableId: string, offerId: string }): Promise<void> => {
        const repository = new ApiOrderRepository();
        await repository.createOrderAttempt(data.orderId, data.aforableId, data.offerId);
    }
);

export const completeOrder = createAsyncThunk(
    `${ORDER_SLICE}/completeOrder`,
    async (data: {orderId: string }): Promise<void> => {
        const repository = new ApiOrderRepository();
        await repository.completeOrder(data.orderId);
    }
);

export const {clearOrder} = orderSlice.actions

export default orderSlice.reducer
