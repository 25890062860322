import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ActionConfirmationDialog from "../ActionConfirmationDialog/ActionConfirmationDialog";
import AforableUpgradePremiumDialogProps from "./AforableUpgradePremiumDialogProps";
import {useOrderListByAforableId} from "../../../order/hooks/useOrderListByAforableId";
import {fetchOrdersByOrganization} from "../../../order/state/orderSlice";
import {useAppDispatch} from "../../hooks/stateHooks";
import {useOrderListByOrganizationId} from "../../../order/hooks/useOrderListByOrganizationId";
import {useTranslate} from "../../hooks/useTranslate";

function AforableUpgradePremiumDialog(props: AforableUpgradePremiumDialogProps) {

    const aforableOrderCollection = useOrderListByAforableId(props.aforable.id);
    const organizationOrderCollection = useOrderListByOrganizationId(props.aforable.organizationId);
    const [isModalOpen, setModalOpen] = useState(false);
    const { t } = useTranslate('shared', 'Component.AforableUpgradePremiumDialog');

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const onUpgrade = () => {
        setModalOpen(false);
        navigate(`/spaces/${props.aforable.id}/upgrade-premium`);
    };

    const title = () => {
        return organizationOrderCollection.isEmpty()
            ? t('try_it_for_free')
            : t('upgrade_to_premium');
    };

    useEffect(() => {

        const hasAforableToBePremium = (): boolean => {
            return !aforableOrderCollection.hasAnyActiveOrder() && props.isPremiumFeatureRequested;
        };

        setModalOpen(hasAforableToBePremium());
    }, [aforableOrderCollection, props.isPremiumFeatureRequested]);

    useEffect( () => {
        dispatch(fetchOrdersByOrganization({organizationId: props.aforable.organizationId}));
    }, [dispatch, props.aforable.organizationId]);

    return (
        <ActionConfirmationDialog
            open={isModalOpen}
            title={title()}
            body={t('upgrade_to_premium_sub')}
            dismissButtonLabel={t('skip')}
            confirmationButtonLabel={t('upgrade_now')}
            onDismiss={props.onDismiss}
            onConfirm={onUpgrade}
        />
    );
}

export default AforableUpgradePremiumDialog;
