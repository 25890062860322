import React, {useEffect} from 'react';
import AuthLayoutProps from './AuthLayoutProps';
import {Box, Container, CssBaseline} from '@mui/material';
import Copyright from '../../../../shared/components/Copyright/Copyright';
import {useAuthSelector} from '../../../hooks/useAuthSelector';
import {useAppDispatch} from '../../../../shared/hooks/stateHooks';
import {useNavigate} from 'react-router-dom';
import {getAuthStatus} from '../../../state/authSlice';
import LanguageSelector from "../../../../shared/components/LanguageSelector/LanguageSelector";

function AuthLayout(props: AuthLayoutProps) {

    const auth = useAuthSelector();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getAuthStatus());
    }, [dispatch]);

    useEffect(() => {

        if (auth.isPending) {
            return;
        }

        if (!auth.isLoggedIn) {
            return;
        }
        navigate('/spaces')
    }, [auth, navigate]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {props.children}
            </Box>
            <Copyright sx={{ mt: 8 }} />
            <LanguageSelector/>
        </Container>
    );
}



export default AuthLayout;
