import FacebookLoginButton from "../FacebookLoginButton/FacebookLoginButton";
import {useAppDispatch} from "../../../../../../shared/hooks/stateHooks";
import {loginWithFacebook} from "../../../../../state/authSlice";
import useFacebook from "../../../../../hooks/useFacebook";
import {AuthResponse} from "../../../../../domain/to/AuthResponse";
import {usePrepopulatedSignUpNavigate} from "../../../../../hooks/usePrepopulatedSignUpNavigate";
import {PrePopulatedUserData} from "../../../../../domain/to/PrePopulatedUserData";
import {useTranslate} from "../../../../../../shared/hooks/useTranslate";


function FacebookLogin() {

    const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID || '';
    const facebook = useFacebook(facebookAppId);
    const dispatch = useAppDispatch();
    const signUpNavigate = usePrepopulatedSignUpNavigate();
    const { t } = useTranslate('auth', 'LogInPage.FacebookLogin');

    const onLoginResponseCallback = (response: any) => {

        const facebookToken = response.authResponse.accessToken;

        (async () => {
            try {
                await dispatch(loginWithFacebook({facebookToken})).unwrap();
            } catch (e: any) {
                manageLoginFailure(e);
            }
        })();
    };

    const manageLoginFailure = (failedAuthResponse: AuthResponse) => {

        if (!failedAuthResponse.hasFailed) {
            // Tu que merdes fas aquí?
            // TODO: Show an error!
            return;
        }

        if (failedAuthResponse.errorCode !== 'BAD_CREDENTIALS_EXCEPTION') {
            // Unexpected error
            // TODO: Show an error!
            return;
        }

        redirectToSignUp();
    };

    const login = () => {
        facebook?.login(onLoginResponseCallback);
    };

    const redirectToSignUp = () => {
        facebook?.userData((userData: PrePopulatedUserData) => {
            signUpNavigate(userData);
        });
    };

    return (
        <FacebookLoginButton
            appId={facebookAppId}
            onLoginResponseCallback={login}
            buttonLabel={t('login_with_facebook')}
        />
    );
}

export default FacebookLogin;