import {
    AppBar,
    IconButton, Paper,
    Toolbar,
    Typography
} from "@mui/material";
import React, {useState} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import TopAppBarDrawer from '../TopAppBarDrawer/TopAppBarDrawer';
import {useAppDispatch} from '../../hooks/stateHooks';
import {logout} from '../../../auth/state/authSlice';



function TopAppBar() {

    const [isOpen, toggle] = useState(false);
    const dispatch = useAppDispatch();

    const logoutHandler = () => dispatch(logout());


    return (
        <Paper sx={{ position: 'sticky', zIndex: 2}} elevation={3}>
            <AppBar>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => toggle(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div">
                        Aforable
                    </Typography>
                </Toolbar>
            </AppBar>
            <TopAppBarDrawer isOpen={isOpen} toggle={toggle} logout={logoutHandler}/>
        </Paper>
    );
}



export default TopAppBar;
