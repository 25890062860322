import {createAsyncThunk, createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../shared/state/store';
import {AforableState} from './AforableState';
import {ApiAforableRepository} from '../repository/ApiAforableRepository';
import {Aforable} from '../domain/Aforable';
import {AforableSerializer} from "./serialization/aforableSerializer";
import {AforableSerialization} from "./serialization/AforableSerialization";

const initialState: AforableState = { isPending: false, listById: {}};
const AFORABLE_SLICE = 'aforable';
const serializer = new AforableSerializer();

export const aforableSlice = createSlice({
    name: AFORABLE_SLICE,
    initialState,
    reducers: {
        clearAforables: () => initialState,
        addAforables: (state: Draft<AforableState>, action: PayloadAction<AforableSerialization[]>) => {

            const aforableList = action.payload.reduce((acc: {[aforableId: string]: AforableSerialization}, aforable: AforableSerialization) => {
                acc[aforable.id] = aforable;
                return acc;
            }, {});

            return {
                ...state,
                listById: {...state.listById, ...aforableList},
            }

        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAforables.pending, (state: Draft<AforableState>): AforableState => {
                return {...state, isPending: true};
            })
            .addCase(fetchAforables.fulfilled, (state: Draft<AforableState>, action: PayloadAction<AforableSerialization[]>): AforableState => {

                const aforableList = action.payload.reduce((acc: {[aforableId: string]: AforableSerialization}, aforable: AforableSerialization) => {
                    acc[aforable.id] = aforable;
                    return acc;
                }, {});

                return {
                    ...state,
                    isPending: false,
                    listById: {...state.listById, ...aforableList},
                }
            })
            .addCase(fetchAforableById.fulfilled, (state: Draft<AforableState>, action: PayloadAction<AforableSerialization>): AforableState => {

                return {
                    ...state,
                    listById: {...state.listById, [action.payload.id]: action.payload},
                }
            })
            .addCase(editAforable.fulfilled, (state: Draft<AforableState>, action: PayloadAction<AforableSerialization>): AforableState => {
                return {
                    ...state,
                    listById: {...state.listById, [action.payload.id]: action.payload},
                }
            })
            .addCase(createAforable.fulfilled, (state: Draft<AforableState>, action: PayloadAction<AforableSerialization>): AforableState => {
                return {
                    ...state,
                    listById: {...state.listById, [action.payload.id]: action.payload},
                }
            })
            .addCase(removeAforable.fulfilled, (state: Draft<AforableState>, action: PayloadAction<AforableSerialization>): AforableState => {

                const aforable = action.payload;

                const { [aforable.id]: _, ...remainingSpaces } = state.listById;

                return {...state, listById: {...remainingSpaces}}
            })
            .addCase(increaseAforableUsedCapacity.pending, (state: Draft<AforableState>, action: any): AforableState => {
                const aforable: Aforable = action.meta.arg.aforable;
                aforable.increaseUsedCapacity()

                return {
                    ...state,
                    listById: {
                        ...state.listById,
                        [aforable.id]: serializer.serialize(aforable)
                    },
                }
            })
            .addCase(decreaseAforableUsedCapacity.pending, (state: Draft<AforableState>, action: any): AforableState => {
                const aforable: Aforable = action.meta.arg.aforable;
                aforable.decreaseUsedCapacity()

                return {
                    ...state,
                    listById: {
                        ...state.listById,
                        [aforable.id]: serializer.serialize(aforable)
                    },
                }
            })
            .addCase(resetAforableUsedCapacity.pending, (state: Draft<AforableState>, action: any): AforableState => {
                const aforable: Aforable = action.meta.arg.aforable;
                aforable.resetUsedCapacity()

                return {
                    ...state,
                    listById: {
                        ...state.listById,
                        [aforable.id]: serializer.serialize(aforable)
                    },
                }
            })
            .addCase(fetchAforableBySlug.fulfilled, (state: Draft<AforableState>, action: PayloadAction<AforableSerialization>): AforableState => {

                return {
                    ...state,
                    listById: {...state.listById, [action.payload.id]: action.payload},
                }
            })
    }
});

export const fetchAforables = createAsyncThunk(
    `${AFORABLE_SLICE}/fetchAforables`,
    async (data: void, thunk): Promise<AforableSerialization[]> => {

        const state = thunk.getState() as RootState;
        const {activeOrganizationId} = state.organization

        if (!activeOrganizationId) {
            return [];
        }

        const repository = new ApiAforableRepository();
        const aforables = await repository.aforables(activeOrganizationId);
        return aforables.map((aforable: Aforable) => serializer.serialize(aforable));
    }
);

export const fetchAforableById = createAsyncThunk(
    `${AFORABLE_SLICE}/fetchAforableById`,
    async (data: {aforableId: string}): Promise<AforableSerialization> => {

        const repository = new ApiAforableRepository();
        const aforable = await repository.aforableById(data.aforableId);
        return serializer.serialize(aforable);
    }
);

export const fetchAforableBySlug = createAsyncThunk(
    `${AFORABLE_SLICE}/fetchAforableBySlug`,
    async (data: {slug: string}): Promise<AforableSerialization> => {

        const repository = new ApiAforableRepository();
        const aforable = await repository.aforableBySlug(data.slug);
        return serializer.serialize(aforable);
    }
);

export const editAforable = createAsyncThunk(
    `${AFORABLE_SLICE}/editAforable`,
    async (data: {aforable: Aforable}): Promise<AforableSerialization> => {

        const repository = new ApiAforableRepository();
        await repository.editAforable(data.aforable);
        return serializer.serialize(data.aforable);
    }
);

export const createAforable = createAsyncThunk(
    `${AFORABLE_SLICE}/createAforable`,
    async (data: {aforable: Aforable}): Promise<AforableSerialization> => {

        const repository = new ApiAforableRepository();
        await repository.createAforable(data.aforable);
        return serializer.serialize(data.aforable);
    }
);

export const removeAforable = createAsyncThunk(
    `${AFORABLE_SLICE}/removeAforable`,
    async (data: {aforable: Aforable}): Promise<AforableSerialization> => {

        const repository = new ApiAforableRepository();
        await repository.removeAforable(data.aforable);
        return serializer.serialize(data.aforable);
    }
);

export const increaseAforableUsedCapacity = createAsyncThunk(
    `${AFORABLE_SLICE}/increaseAforableUsedCapacity`,
    async (data: {aforable: Aforable}, thunk): Promise<void> => {
        const repository = new ApiAforableRepository();
        await repository.increaseAforableUsedCapacity(data.aforable);
        await thunk.dispatch(fetchAforableById({aforableId: data.aforable.id}));
    }
);

export const decreaseAforableUsedCapacity = createAsyncThunk(
    `${AFORABLE_SLICE}/decreaseAforableUsedCapacity`,
    async (data: {aforable: Aforable}, thunk): Promise<void> => {

        const repository = new ApiAforableRepository();
        await repository.decreaseAforableUsedCapacity(data.aforable);
        await thunk.dispatch(fetchAforableById({aforableId: data.aforable.id}));
    }
);

export const resetAforableUsedCapacity = createAsyncThunk(
    `${AFORABLE_SLICE}/resetAforableUsedCapacity`,
    async (data: {aforable: Aforable}, thunk): Promise<void> => {

        const repository = new ApiAforableRepository();
        await repository.resetAforableUsedCapacity(data.aforable);
        await thunk.dispatch(fetchAforableById({aforableId: data.aforable.id}));
    }
);

export const {clearAforables, addAforables} = aforableSlice.actions

export default aforableSlice.reducer
