
import React from 'react';
import {AforableFormBaseProps} from "./AforableFormBaseProps";
import {Avatar, Box, Button, Card, CardActions, CardContent, FormControl, TextField, Typography} from "@mui/material";
import {
    BusinessCenter,
    Home,
    Language,
    Save
} from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import aforableTypeList from "../../domain/AforableTypeList";
import {useTranslate} from "../../../shared/hooks/useTranslate";

function AforableFormBase(props: AforableFormBaseProps) {

    const { t } = useTranslate('aforable', 'Component.AforableFormBase');

    const {formik} = props;

    const aforableTypeOptions = aforableTypeList.map((type) => {
        return <MenuItem value={type.id} key={type.id}>{type.label}</MenuItem>;
    });

    return (
        <Card>
            <form onSubmit={formik.handleSubmit}>
                <CardContent sx={{pb: 0}}>
                    <Typography component="div" variant="h5">{props.cardTitle}</Typography>

                    <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                        <Avatar sx={{bgcolor: 'primary.main', mr: 2}}>
                            <Home/>
                        </Avatar>
                        <FormControl fullWidth>
                            <TextField
                                id="name"
                                name="name"
                                label={`${t('name')} *`}
                                variant="outlined"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.errors.name && formik.touched.name && String(formik.errors.name)}
                            />
                        </FormControl>
                    </Box>

                    <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                        <Avatar sx={{ bgcolor: 'primary.main', mr: 2}}>
                            <BusinessCenter />
                        </Avatar>
                        <FormControl fullWidth>
                            <InputLabel id="type">Type *</InputLabel>
                            <Select
                                labelId="type"
                                id="type"
                                name="type"
                                label={`${t('type')} *`}
                                value={formik.values.type}
                                onChange={formik.handleChange}
                                error={formik.touched.type && Boolean(formik.errors.type)}
                            >
                                {aforableTypeOptions}
                            </Select>
                        </FormControl>
                    </Box>


                    {props.maxAllowedCapacityFormField}

                    {props.slugFormField}

                    <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                        <Avatar sx={{bgcolor: 'primary.main', mr: 2}}>
                            <Language/>
                        </Avatar>
                        <FormControl fullWidth>
                            <TextField
                                id="website"
                                name="website"
                                label={`${t('website')} *`}
                                variant="outlined"
                                type="text"
                                value={formik.values.website}
                                onChange={formik.handleChange}
                                error={formik.touched.website && Boolean(formik.errors.website)}
                                helperText={formik.errors.website && formik.touched.website && String(formik.errors.website)}
                            />
                        </FormControl>
                    </Box>
                </CardContent>
                <CardActions sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button startIcon={<Save/>} color="primary" type="submit" disabled={props.isSubmitDisabled}>
                        {props.submitLabel}
                    </Button>
                </CardActions>
            </form>
        </Card>
    )

}

export default AforableFormBase;
