import React, {useEffect, useState} from "react";
import {useIsUserLoggedPartOfAnOrganization} from "../../../organizationMember/hooks/useIsUserLoggedPartOfAnOrganization";
import {useNavigate} from "react-router-dom";
import InfoDialog from "../InfoDialog/InfoDialog";
import {useTranslate} from "../../hooks/useTranslate";

function FirstOrganizationCreationDialog() {

    const isUserPartOfAnOrganization = useIsUserLoggedPartOfAnOrganization();
    const navigate = useNavigate();
    const { t } = useTranslate('shared', 'Component.FirstOrganizationCreationDialog');

    const [isCreateFirstOrganizationModalOpen, setCreateFirstOrganizationModalOpen] = useState(false);

    useEffect(() => {
        setCreateFirstOrganizationModalOpen(!isUserPartOfAnOrganization);
    }, [isUserPartOfAnOrganization]);

    const onCreateFirstOrganizationModalClose = () => {
        setCreateFirstOrganizationModalOpen(false);
        navigate('/organizations/new');
    }

    return (
        <InfoDialog
            open={isCreateFirstOrganizationModalOpen}
            title={t('create_first_organization')}
            body={t('create_first_organization_sub')}
            buttonLabel={t('create_organization')}
            onClose={onCreateFirstOrganizationModalClose}
        />
    );
}

export default FirstOrganizationCreationDialog;
