import {Groups, Home, Person} from '@mui/icons-material';
import {DrawerOption} from './DrawerOption';

export function options(organizationId?: string): DrawerOption[] {
    return [
        {
            label: "spaces",
            icon: <Home />,
            path: '/spaces',
        },
        {
            label: "organization",
            icon: <Groups />,
            path: `/organizations/${organizationId}`,
        },
        {
            label: "profile",
            icon: <Person />,
            path: '/profile',
        }
    ];
}
