import React from 'react';
import {Add} from "@mui/icons-material";
import AforableCapacityBaseControl, {ICON_SX_PROPERTIES} from "./AforableCapacityBaseControl";
import {AforableCapacityControlProps} from "./AforableCapacityControlProps";


function AforableCapacityIncreaseControl({isDisabled, onClick}: AforableCapacityControlProps) {

    return (
        <AforableCapacityBaseControl
            isDisabled={isDisabled}
            onClick={onClick}
            icon={<Add sx={ICON_SX_PROPERTIES}/>}
        />
    );
}

export default AforableCapacityIncreaseControl;
