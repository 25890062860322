import {Link as MuiLink} from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import LinkProps, {defaultProps} from './LinkProps';

function Link(props: LinkProps) {
    return (
        <MuiLink
            to={props.to}
            component={RouterLink}
            color={props.color}
            variant={props.variant}
            classes={props.classes}
        >
            {props.children}
        </MuiLink>
    );
}

Link.defaultProps = defaultProps;

export default Link;
