import {Box, Button, CircularProgress, Grid, TextField} from '@mui/material';
import React, {useState} from 'react';
import {useAppDispatch} from "../../../../shared/hooks/stateHooks";
import * as Yup from "yup";
import {useFormik} from "formik";
import {sendPasswordRecoveryMail} from "../../../state/authSlice";
import InfoDialog from "../../../../shared/components/InfoDialog/InfoDialog";
import {useTranslate} from "../../../../shared/hooks/useTranslate";
import AlreadyHaveAnAccountLink from "../../shared/AlreadyHaveAnAccountLink/AlreadyHaveAnAccountLink";

function ForgotPasswordEmailForm() {

    const dispatch = useAppDispatch();
    const { t, td } = useTranslate('auth', 'ForgotPasswordPage.ForgotPasswordEmailForm')
    const [inProgress, setInProgress] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    const initialValues = { email: '' };

    const validationSchema = Yup.object({
        email: Yup.string().required(td('validation.required')).email(td('validation.email')),
    });

    const onSubmit = async (values: any, actions: any) => {
        setInProgress(true);

        const email = values.email as string

        try {
            await dispatch(sendPasswordRecoveryMail({email})).unwrap();
            setModalOpen(true);
        } catch (error) {
            actions.setFieldError('email', td('error.generic'))
        }

        setInProgress(false);
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    return (
        <Box sx={{mt: 1}} width="100%">
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    margin="normal"
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                    disabled={inProgress}
                >
                    {inProgress && <CircularProgress size={25}/>}
                    {!inProgress && t('send_login_link')}

                </Button>
                <Grid container>
                    <Grid item xs>
                        <AlreadyHaveAnAccountLink/>
                    </Grid>
                </Grid>
            </form>
            <InfoDialog
                open={isModalOpen}
                title={`${t('message_sent')}!`}
                body={t('message_sent_sub')}
                buttonLabel={td('info.got_it')}
                onClose={() => setModalOpen(false)}
            />
        </Box>
    );
}

export default ForgotPasswordEmailForm
