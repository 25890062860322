import {Order} from '../Order';
import {OrderActivationDateRange} from "../OrderActivationDateRange";
import {OrderPricing} from "../OrderPricing";
import {OrderMetadataBuilder} from "./OrderMetadataBuilder";

export class OrderBuilder {

    private metadataBuilder: OrderMetadataBuilder;

    constructor() {
        this.metadataBuilder = new OrderMetadataBuilder();
    }


    build(data: any): Order {

        return new Order(
            data.id,
            data.aforableId,
            new Date(data.createdAt),
            new OrderActivationDateRange(
                new Date(data.activationDateRange.startDate),
                new Date(data.activationDateRange.endDate)
            ),
            new OrderPricing(
                data.pricing.amount,
                data.pricing.currency,
            ),
            data.status,
            data.isActive,
            data.isFreeTrial,
            this.metadataBuilder.build(data.metadata)
        );

    }
}
