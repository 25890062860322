import {Button, Card, CardActions, CardContent, Tooltip, Typography} from "@mui/material";
import React, {useState} from "react";
import {Delete} from "@mui/icons-material";
import {RemoveAforableProps} from "./RemoveAforableProps";
import {useActiveOrderByAforableId} from "../../../order/hooks/useActiveOrderByAforableId";
import {useAppDispatch} from "../../../shared/hooks/stateHooks";
import ActionConfirmationDialog from "../../../shared/components/ActionConfirmationDialog/ActionConfirmationDialog";
import {removeAforable} from "../../state/aforableSlice";
import {useNavigate} from "react-router-dom";
import {useTranslate} from "../../../shared/hooks/useTranslate";

function RemoveAforable({aforable}: RemoveAforableProps) {

    const activeOrder = useActiveOrderByAforableId(aforable.id);
    const [isModelOpen, setIsModalOpen] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, td } = useTranslate('aforable', 'AforableDetailPage.RemoveAforable');

    const onRemove = async () => {
        await dispatch(removeAforable({aforable})).unwrap();
        navigate(`/spaces`);
    };


    return (
        <Card>
            <CardContent sx={{pb: 0}}>
                <Typography component="div" variant="h5">{t('remove_space')}</Typography>
                <Typography variant="body2" color="text.secondary">{t('remove_space_sub')}</Typography>
            </CardContent>
            <CardActions sx={{display: "flex", justifyContent: "flex-end", mt: 2}}>
                <Tooltip title={ !!activeOrder ? t('unable_to_remove_space_tooltip') : ''}>
                    <span>
                        <Button startIcon={<Delete/>} color="error" onClick={() => setIsModalOpen(true)} disabled={!!activeOrder}>
                            {td('action.remove')}
                        </Button>
                    </span>
                </Tooltip>
            </CardActions>
            <ActionConfirmationDialog
                open={isModelOpen}
                title={t('remove_space_confirmation')}
                body={t('remove_space_confirmation_sub', {spaceName: aforable.name})}
                dismissButtonLabel={td('action.cancel')}
                confirmationButtonLabel={td('action.remove')}
                onDismiss={() => setIsModalOpen(false)}
                onConfirm={onRemove}
            />
        </Card>
    );
}

export default RemoveAforable;