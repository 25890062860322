import {
    Card,
    CardContent,
    Typography
} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {AforableUpgradePremiumProps} from './AforableUpgradePremiumProps';
import {useAppDispatch} from "../../../shared/hooks/stateHooks";
import {createOrderAttempt, fetchOrderById} from "../../state/orderSlice";
import {useUUID} from "../../../shared/hooks/useUUID";
import {useOrderById} from "../../hooks/useOrderById";
import {StripeOrderMetadata} from "../../domain/StripeOrderMetadata";
import {useNavigate} from "react-router-dom";
import AforableUpgradePremiumForm from "../AforableUpgradePremiumForm/AforableUpgradePremiumForm";
import {useTranslate} from "../../../shared/hooks/useTranslate";


function AforableUpgradePremium(props: AforableUpgradePremiumProps) {

    const newUuid = useUUID();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslate('order', 'AforableUpgradePremiumPage.AforableUpgradePremium');

    const orderId = useRef(newUuid);
    const createdOrderAttempt = useOrderById(orderId.current);

    const [isLoading, setLoading] = useState(false);

    const selectedAforable = props.selectedAforable;

    const onSubmit = async (values: any, actions: any) => {

        setLoading(true);

        const payload = {
            orderId: orderId.current,
            aforableId: values.aforable as string,
            offerId: values.offer as string,
        };

        try {
            await dispatch(createOrderAttempt(payload)).unwrap();
            await dispatch(fetchOrderById({orderId: orderId.current})).unwrap();
        } catch (error) {
            actions.setFieldError('offer', 'Something went wrong. Please, try again later.');
            setLoading(false);
        }
    };

    useEffect(() => {

        if (!createdOrderAttempt) {
            return;
        }

        if (createdOrderAttempt.metadata instanceof StripeOrderMetadata) {
            window.location.href = createdOrderAttempt.metadata.checkoutURL();
            return;
        }

        navigate(`/spaces/${selectedAforable?.id}/upgrade-premium/${orderId.current}/completion`)

    }, [createdOrderAttempt, navigate, orderId, selectedAforable])


    return (
        <Card>
            <CardContent sx={{pb: 0}}>
                <Typography component="div" variant="h5">{t('upgrade_premium')}</Typography>
                <AforableUpgradePremiumForm
                    aforables={props.aforables}
                    offers={props.offers}
                    onSubmit={onSubmit}
                    isLoading={isLoading}
                    selectedAforable={selectedAforable}
                />
            </CardContent>
        </Card>
    );
}

export default AforableUpgradePremium;
