
import {useAppDispatch} from '../../../shared/hooks/stateHooks';
import React, {useEffect} from 'react';
import {fetchUserLogged} from '../../state/userSlice';
import {useUserLogged} from '../../../auth/hooks/useUserLogged';
import PrivateLayout from '../../../shared/components/PrivateLayout/PrivateLayout';
import {Box, CircularProgress, Container} from '@mui/material';
import EditProfileForm from '../../components/EditProfileForm/EditProfileForm';

function EditProfilePage() {

    const dispatch = useAppDispatch();
    const userLogged = useUserLogged();

    useEffect(() => {
        dispatch(fetchUserLogged());
    }, [dispatch]);

    return (
        <PrivateLayout>
            <Container maxWidth={'sm'} sx={{px: 0}}>
                {userLogged ? (
                    <EditProfileForm userLogged={userLogged}/>
                ): (
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress/>
                    </Box>
                )}
            </Container>
        </PrivateLayout>
    );
}

export default EditProfilePage;
