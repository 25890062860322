import {createAsyncThunk, createSlice, Draft, PayloadAction} from '@reduxjs/toolkit'
import {UserState} from './UserState';
import {User} from '../domain/User';
import {AuthState} from '../../auth/state/AuthState';
import {RootState} from '../../shared/state/store';
import {ApiUserRepository} from '../repository/ApiUserRepository';
import {logout} from '../../auth/state/authSlice';
import ApiError from '../../shared/domain/error/ApiError';


const initialState: UserState = { isPending: false, listById: {}};
const USER_SLICE = 'user'

export const userSlice = createSlice({
    name: USER_SLICE,
    initialState,
    reducers: {
        clearUser: () => initialState,
        addUserLogged: (state: Draft<UserState>, action: PayloadAction<User>) => {
            return {
                ...state,
                listById: {...state.listById, [action.payload.id]: action.payload},
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchUserLogged.fulfilled, (state: Draft<UserState>, action: PayloadAction<User | undefined>): UserState => {
                if (!action.payload) {
                    return state;
                }

                return {
                    ...state,
                    listById: {...state.listById, [action.payload.id]: action.payload},
                }
            })
            .addCase(switchLocale.pending, (state: Draft<UserState>, action): UserState => {

                const initialActionPayload: {locale: string, userId: string} = action.meta.arg;

                const userLogged = state.listById[initialActionPayload.userId];

                const updatedLocaleUserLogged: User = {
                    ...userLogged,
                    locale: initialActionPayload.locale
                }

                return {
                    ...state,
                    listById: {...state.listById, [updatedLocaleUserLogged.id]: updatedLocaleUserLogged},
                }
            })
            .addCase(editUser.fulfilled, (state: Draft<UserState>, action: PayloadAction<User>): UserState => {
                return {
                    ...state,
                    listById: {...state.listById, [action.payload.id]: action.payload},
                }
            })
    }
});


export const fetchUserLogged = createAsyncThunk(
    `${USER_SLICE}/fetchUserLogged`,
    async (data, thunk): Promise<User | undefined> => {

        try {
            const authState: AuthState = (thunk.getState() as RootState).auth;

            if (!authState.userLoggedId) {
                return;
            }

            const apiRepository = new ApiUserRepository();
            return await apiRepository.fetchUserLogged(authState.userLoggedId);
        } catch (e) {
            if (e instanceof ApiError && e.status === 401){
                thunk.dispatch(logout());
            }

        }


    }
);

export const switchLocale = createAsyncThunk(
    `${USER_SLICE}/switchLocale`,
    async (payload: {locale: string, userId: string}): Promise<void> => {
        const apiRepository = new ApiUserRepository();
        await apiRepository.switchLocale(payload.locale, payload.userId);

    }
);

export const changePassword = createAsyncThunk(
    `${USER_SLICE}/changePassword`,
    async (payload: {userId: string, currentPassword: string, newPassword: string}): Promise<void> => {
        const apiRepository = new ApiUserRepository();
        await apiRepository.changePassword(
            payload.userId,
            payload.currentPassword,
            payload.newPassword
        );

    }
);

export const createUser = createAsyncThunk(
    `${USER_SLICE}/createUser`,
    async (payload: {user: User, password: string}): Promise<void> => {
        const apiRepository = new ApiUserRepository();
        await apiRepository.createUser(payload.user, payload.password);
    }
);

export const editUser = createAsyncThunk(
    `${USER_SLICE}/editUser`,
    async (payload: {user: User}): Promise<User> => {
        const apiRepository = new ApiUserRepository();
        await apiRepository.editUser(payload.user);
        return payload.user;
    }
);

export const {clearUser, addUserLogged} = userSlice.actions

export default userSlice.reducer
