import {
    BeachAccess,
    FitnessCenter,
    LocalCafe,
    MenuBook,
    Museum,
    MusicNote,
    Nightlife, Place, Pool, Restaurant, School,
    Storefront, TheaterComedy
} from "@mui/icons-material";
import * as React from "react";

export const DEFAULT_AFORABLE_TYPE = 'other';

const types: { id: string, label: string, icon: React.ReactElement }[] = [
    {
        id: 'bar',
        label: 'Bar',
        icon: <LocalCafe/>
    },
    {
        id: 'beach',
        label: 'Beach',
        icon: <BeachAccess/>
    },
    {
        id: 'concert',
        label: 'Concert',
        icon: <MusicNote/>
    },
    {
        id: 'disco',
        label: 'Disco',
        icon: <Nightlife/>
    },
    {
        id: 'gym',
        label: 'Gym',
        icon: <FitnessCenter/>
    },
    {
        id: 'library',
        label: 'Library',
        icon: <MenuBook/>
    },
    {
        id: 'market',
        label: 'Market',
        icon: <Storefront/>
    },
    {
        id: 'museum',
        label: 'Museum',
        icon: <Museum/>
    },
    {
        id: 'restaurant',
        label: 'Restaurant',
        icon: <Restaurant/>
    },
    {
        id: 'school',
        label: 'School',
        icon: <School/>
    },
    {
        id: 'store',
        label: 'Store',
        icon: <Storefront/>
    },
    {
        id: 'swimming-pool',
        label: 'Swimming pool',
        icon: <Pool/>
    },
    {
        id: 'theater',
        label: 'Theater',
        icon: <TheaterComedy/>
    },
    {
        id: DEFAULT_AFORABLE_TYPE,
        label: 'Other',
        icon: <Place/>
    },
];

export default types;