import {Box, Button, CircularProgress, Grid, TextField} from '@mui/material';
import React from 'react';
import SignUpFormProps from './SignUpFormProps';
import * as Yup from "yup";
import {useFormik} from "formik";
import {useTranslate} from "../../../../shared/hooks/useTranslate";
import AlreadyHaveAnAccountLink from "../../shared/AlreadyHaveAnAccountLink/AlreadyHaveAnAccountLink";

function SignUpForm(props: SignUpFormProps) {

    const prePopulatedUserData = props.prePopulatedUserData;

    const { t, td } = useTranslate('auth', 'SignUpPage.SignUpForm');

    const initialValues = {
        firstName: prePopulatedUserData?.firstName || '',
        lastName: prePopulatedUserData?.lastName || '',
        email: prePopulatedUserData?.email || '',
        password: ''
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required(td('validation.required')),
        lastName: Yup.string().required(td('validation.required')),
        email: Yup.string().required(td('validation.required')).email(td('validation.email')),
        password: Yup.string().required(td('validation.required')).min(6, td('validation.min_characters', {count: 6})),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: props.handleSubmit,
    });

    return (
        <Box sx={{mt: 1}} width="100%">
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    margin="normal"
                    fullWidth
                    id="firstName"
                    label={t('first_name')}
                    name="firstName"
                    autoComplete="given-name"
                    autoFocus
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="lastName"
                    label={t('last_name')}
                    name="lastName"
                    autoComplete="family-name"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="email"
                    label={td('field.email')}
                    name="email"
                    autoComplete="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    name="password"
                    label={td('field.password')}
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                    disabled={props.inProgress}
                >
                    {props.inProgress && <CircularProgress size={25}/>}
                    {!props.inProgress && td('action.signup')}
                </Button>
                <Grid container>
                    <Grid item xs>
                        <AlreadyHaveAnAccountLink/>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}

export default SignUpForm
