import {EditAforableProps} from "./EditAforableProps";
import {Aforable} from "../../domain/Aforable";
import {editAforable} from "../../state/aforableSlice";
import {AforableType} from "../../domain/AforableType";
import {AforableCapacity} from "../../domain/AforableCapacity";
import {useAppDispatch} from "../../../shared/hooks/stateHooks";
import {useNavigate} from "react-router-dom";
import React from "react";
import ExpiredAforablePremiumDialog
    from "../../../shared/components/ExpiredAforablePremiumDialog/ExpiredAforablePremiumDialog";
import EditAforableForm from "../EditAforableForm/EditAforableForm";

function EditAforable({aforable}: EditAforableProps) {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onSubmit = async (values: any) => {
        const aforable: Aforable = buildAforable(values);
        const action = editAforable({aforable});
        await dispatch(action).unwrap();
        navigate(`/spaces/${aforable.id}/detail`);
    };

    const buildAforable = (formValues: any): Aforable => {

        return new Aforable(
            aforable.id,
            formValues.name as string,
            formValues.slug as string,
            AforableType.buildFromString(formValues.type as string),
            new AforableCapacity(
                formValues.maxAllowedCapacity as number,
                aforable.capacity.currentUsedCapacity
            ),
            aforable.organizationId,
            aforable.createdAt,
            new Date(),
            formValues.website as string
        );

    };

    return (
        <>
            <EditAforableForm aforable={aforable} onSubmit={onSubmit}/>
            <ExpiredAforablePremiumDialog
                aforableId={aforable.id}
                onDismiss={() => navigate(`/spaces/${aforable.id}/detail`)}
            />
        </>
    );





}

export default EditAforable;