import {Order} from "../domain/Order";
import {useOrderMap} from "./useOrderMap";

export const useOrderById = (orderId?: string): Order | undefined => {

    const aforableListById = useOrderMap();

    if (!orderId) {
        return;
    }

    return aforableListById[orderId];

}
