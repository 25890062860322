import {AforableCapacity} from './AforableCapacity';
import {AforableType} from "./AforableType";

export class Aforable {

    constructor(
        private _id: string,
        private _name: string,
        private _slug: string,
        private _type: AforableType,
        private _capacity: AforableCapacity,
        private _organizationId: string,
        private _createdAt: Date,
        private _updatedAt: Date,
        private _website?: string,
    ) {}


    get id(): string {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get slug(): string {
        return this._slug;
    }

    get type(): AforableType {
        return this._type;
    }

    get capacity(): AforableCapacity {
        return this._capacity;
    }

    get organizationId(): string {
        return this._organizationId;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    get updatedAt(): Date {
        return this._updatedAt;
    }

    get website(): string | undefined {
        return this._website;
    }

    increaseUsedCapacity(): void {
        this._capacity = this.capacity.increaseCurrentUsedCapacity();
    }

    decreaseUsedCapacity(): void {
        this._capacity = this.capacity.decreaseCurrentUsedCapacity();
    }

    resetUsedCapacity(): void {
        this._capacity = this.capacity.resetCurrentUsedCapacity();
    }

    usedCapacityPercentage(): number {
        return this.capacity.usagePercentage();
    }
}
