import GoogleLogin from "../Methods/Google/GoogleLogin/GoogleLogin";
import FacebookLogin from "../Methods/Facebook/FacebookLogin/FacebookLogin";
import {Box} from "@mui/material";

function LogInThirdParty() {
    return (
        <>
            <GoogleLogin/>
            <Box sx={{mt: 1}}/>
            <FacebookLogin/>
        </>
    );
}

export default LogInThirdParty;
