import {Aforable} from '../domain/Aforable';
import {useAforableList} from "./useAforableList";

export const useAforableBySlug = (slug?: string): Aforable | undefined => {

    const aforables = useAforableList();

    if (!slug) {
        return;
    }

    for (const aforable of aforables) {

        if (aforable.slug === slug) {
            return aforable;
        }
    }

}
