import {OrderSerializer} from "../state/serialization/orderSerializer";
import {Order} from "../domain/Order";
import {useOrder} from "./useOrder";

const serializer = new OrderSerializer();

export const useOrderMap = (): { [p: string]: Order } => {
    const orderState = useOrder();

    const orderMap: {[orderId: string]: Order} = {};

    for (const [key, value] of Object.entries(orderState.listById)) {
        orderMap[key] = serializer.deserialize(value);
    }

    return orderMap;
}
