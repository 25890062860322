export class LocalStorageAbstractRepository {

    set(key: string, data: any): void {
        localStorage.setItem(key, JSON.stringify(data))
    }

    get(key: string) {
        const item = localStorage.getItem(key);

        if(!item) {
            return null;
        }

        return JSON.parse(item);
    }
}
