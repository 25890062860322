import {Avatar, Box, Button, Card, CardActions, Typography} from '@mui/material';
import {AforableCardProps} from './AforableCardProps';
import * as React from 'react';
import CardContent from '@mui/material/CardContent';
import {Exposure, Info} from '@mui/icons-material';
import {useOrganizationById} from '../../../organization/hooks/useOrganizationById';
import {Link} from 'react-router-dom';
import {useTranslate} from "../../../shared/hooks/useTranslate";

function AforableCard({aforable}: AforableCardProps) {

    const organization = useOrganizationById(aforable.organizationId);
    const { t } = useTranslate('aforable', 'AforableListPage.AforableCard');

    if (!organization) {
        return <></>;
    }

    const avatarSize = 60;

    return (
        <Card sx={{ width: "100%", marginBottom: 2, minHeight: 136, mb: 0}}>
            <CardContent sx={{display: 'flex', pb: 1}}>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Avatar sx={{ width: avatarSize, height: avatarSize, bgcolor: 'primary.main'}}>
                        {aforable.type.icon}
                    </Avatar>
                </Box>

                <Box sx={{ml: 2, mt: 0, minHeight: '90px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <Typography variant="h6" component="div">{aforable.name}</Typography>
                    <Typography variant="subtitle1" component="div">{organization.name}</Typography>
                </Box>
            </CardContent>
            <CardActions sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button
                    startIcon={<Info/>}
                    color="primary"
                    component={Link}
                    to={`/spaces/${aforable.id}/detail`}
                >
                    { t('detail') }
                </Button>

                <Button
                    startIcon={<Exposure/>}
                    color="primary"
                    component={Link}
                    to={`/spaces/${aforable.id}/capacity-management`}
                >
                    { t('manage_capacity') }
                </Button>
            </CardActions>
        </Card>
    )
}

export default AforableCard;
