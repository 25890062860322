import {Aforable} from '../../domain/Aforable';
import AforableCard from '../AforableCard/AforableCard';
import AddAforableCard from '../AddAforableCard/AddAforableCard';
import {Container} from '@mui/material';
import {AforableListProps} from './AforableListProps';

function AforableList(props: AforableListProps) {
    const aforableList = props.aforables;

    return (
        <Container sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(21rem, 1fr))",
            gridGap: "1rem",
            justifySelf: "center",
            justifyItems: 'center',
            px: 0
        }}>
            {aforableList.map(
                (aforable: Aforable) => <AforableCard  key={aforable.id} aforable={aforable}/>
            )}
            <AddAforableCard/>
        </Container>
    );
}

export default AforableList;
