import {OrderActivationDateRange} from './OrderActivationDateRange';
import {OrderPricing} from './OrderPricing';
import {OrderMetadata} from "./OrderMetadata";

export class Order {

    constructor(
        private _id: string,
        private _aforableId: string,
        private _createdAt: Date,
        private _activationDateRange: OrderActivationDateRange,
        private _pricing: OrderPricing,
        private _status: string,
        private _isActive: boolean,
        private _isFreeTrial: boolean,
        private _metadata?: OrderMetadata
    ) {}


    get id(): string {
        return this._id;
    }

    get aforableId(): string {
        return this._aforableId;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    get activationDateRange(): OrderActivationDateRange {
        return this._activationDateRange;
    }

    get pricing(): OrderPricing {
        return this._pricing;
    }

    get status(): string {
        return this._status;
    }

    get isActive(): boolean {
        return this._isActive;
    }

    get isFreeTrial(): boolean {
        return this._isFreeTrial;
    }

    get metadata(): OrderMetadata | undefined {
        return this._metadata;
    }
}
