import {
    Avatar, Box,
    Button,
    Card,
    CardActions,
    CardContent, Divider, FormControl, TextField,
    Typography
} from '@mui/material';
import React, {useState} from 'react';
import {Lock, Password, Save, SyncLock} from '@mui/icons-material';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import InfoDialog from '../../../shared/components/InfoDialog/InfoDialog';
import {useAppDispatch} from '../../../shared/hooks/stateHooks';
import {changePassword} from '../../state/userSlice';
import {useUserLogged} from '../../../auth/hooks/useUserLogged';
import {useTranslate} from "../../../shared/hooks/useTranslate";

function ChangePassword() {

    const userLogged = useUserLogged();
    const [isModalOpen, setModalOpen] = useState(false);
    const [isSubmitDisabled, setSubmitDisabled] = useState(false);
    const dispatch = useAppDispatch();

    const { t, td } = useTranslate('user', 'ProfilePage.ChangePassword');

    const initialValues = {
        currentPassword: '',
        newPassword: '',
        passwordConfirmation: ''
    };

    const validationSchema = Yup.object({
        currentPassword: Yup.string()
            .required(td('validation.required')),
        newPassword: Yup.string()
            .required(td('validation.required'))
            .min(6, td('validation.min_characters', {count: 6})),
        passwordConfirmation: Yup.string()
            .required(td('validation.required'))
            .oneOf([Yup.ref('newPassword'), null], t('passwords_must_match')),
    });

    const onSubmit = async (values: any, actions: any) => {

        const payload = {
            userId: userLogged?.id as string,
            currentPassword: values.currentPassword as string,
            newPassword: values.newPassword as string
        };

        try {
            setSubmitDisabled(true);
            await dispatch(changePassword(payload)).unwrap();
            setModalOpen(true);
            formik.resetForm();
        } catch (error) {
            actions.setFieldError('currentPassword', 'Wrong current password')
        } finally {
            setSubmitDisabled(false);
        }


    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    return (
        <Card>
            <form onSubmit={formik.handleSubmit}>
                <CardContent sx={{pb: 0}}>
                    <Typography component="div" variant="h5">{t('change_password')}</Typography>
                    <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                        <Avatar sx={{bgcolor: 'primary.main', mr: 2}}>
                            <Password/>
                        </Avatar>
                        <FormControl fullWidth>
                            <TextField
                                id="currentPassword"
                                name="currentPassword"
                                label={t('current_password')}
                                variant="outlined"
                                type="password"
                                autoComplete="current-password"
                                value={formik.values.currentPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                                helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                            />
                        </FormControl>
                    </Box>

                    <Divider variant="middle" sx={{m: 3}}/>

                    <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                        <Avatar sx={{bgcolor: 'primary.main', mr: 2}}>
                            <Lock/>
                        </Avatar>
                        <FormControl fullWidth>
                            <TextField
                                id="newPassword"
                                name="newPassword"
                                label={t('new_password')}
                                variant="outlined"
                                type="password"
                                autoComplete="new-password"
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                helperText={formik.touched.newPassword && formik.errors.newPassword}
                            />
                        </FormControl>
                    </Box>

                    <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                        <Avatar sx={{bgcolor: 'primary.main', mr: 2}}>
                            <SyncLock/>
                        </Avatar>
                        <FormControl fullWidth>
                            <TextField
                                id="passwordConfirmation"
                                name="passwordConfirmation"
                                label={t('repeat_password')}
                                variant="outlined"
                                type="password"
                                autoComplete="new-password"
                                value={formik.values.passwordConfirmation}
                                onChange={formik.handleChange}
                                error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
                                helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
                            />
                        </FormControl>
                    </Box>
                </CardContent>
                <CardActions sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button startIcon={<Save/>} color="primary" type="submit" disabled={isSubmitDisabled}>
                        {td('action.save')}
                    </Button>
                </CardActions>
            </form>
            <InfoDialog open={isModalOpen} title={`${td('info.success')}!`} body={t('password_updated_success')} buttonLabel={td('info.got_it')} onClose={() => setModalOpen(false)}/>
        </Card>
    );
}

export default ChangePassword;
