import React from 'react';
import PrivateLayout from '../../../shared/components/PrivateLayout/PrivateLayout';
import {Box, CircularProgress, Container} from '@mui/material';
import {useActiveOrganization} from "../../../organization/hooks/useActiveOrganization";
import CreateAforable from "../../components/CreateAforable/CreateAforable";

function CreateAforablePage() {

    const organization = useActiveOrganization();

    return (
        <PrivateLayout>
            <Container maxWidth={'sm'} sx={{px: 0}}>
                { organization ?
                    (
                        <CreateAforable organization={organization}/>
                    ): (
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress/>
                        </Box>
                    )
                }

            </Container>
        </PrivateLayout>
    );
}

export default CreateAforablePage;
