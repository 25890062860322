import {Aforable} from '../domain/Aforable';
import {useAforable} from './useAforable';
import {AforableSerializer} from "../state/serialization/aforableSerializer";

const serializer = new AforableSerializer();

export const useAforableMap = (): { [p: string]: Aforable } => {
    const aforableState = useAforable();

    const aforableList: {[aforableId: string]: Aforable} = {};

    for (const [key, value] of Object.entries(aforableState.listById)) {
        aforableList[key] = serializer.deserialize(value);
    }

    return aforableList;
}
