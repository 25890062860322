import {
    Avatar, Button,
    Card,
    CardActions,
    CardContent,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from '@mui/material';
import React from 'react';
import {Face, PersonAdd} from '@mui/icons-material';
import {useActiveOrganization} from '../../../organization/hooks/useActiveOrganization';
import {OrganizationMember} from '../../domain/OrganizationMember';
import {useOrganizationMemberListByOrganizationId} from '../../hooks/useOrganizationMemberListByOrganizationId';
import {useUser} from '../../../user/hooks/useUser';
import {useTranslate} from "../../../shared/hooks/useTranslate";

function OrganizationMemberList() {

    const userState = useUser();
    const activeOrganization = useActiveOrganization();
    const organizationMemberList = useOrganizationMemberListByOrganizationId(activeOrganization?.id);
    const { t } = useTranslate('organizationMember', 'Component.OrganizationMemberList');

    const membersRendered = organizationMemberList.map((member: OrganizationMember) => {

        const user = userState.listById[member.userId];

        return (
            <ListItem key={member.id}>
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'primary.main'}}>
                        <Face />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`${user?.firstName} ${user?.lastName}`} secondary={`${t('owner')} · ${t('active')}`} />
            </ListItem>
        )
    });

    return (
        <Card>
            <CardContent sx={{pb: 0}}>
                <Typography component="div" variant="h5">{t('organization_members')}</Typography>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {membersRendered}
                </List>
            </CardContent>
            <CardActions sx={{display: "flex", justifyContent: "flex-end"}}>
                <Button startIcon={<PersonAdd/>} color="primary" disabled>
                    {t('add_member')}
                </Button>
            </CardActions>
        </Card>
    );
}

export default OrganizationMemberList;
