import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import ActionConfirmationDialogProps from "./ActionConfirmationDialogProps";

function ActionConfirmationDialog(props: ActionConfirmationDialogProps) {

    return (
        <Dialog onClose={props.onDismiss} open={props.open}>
            <DialogTitle>
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{props.body}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDismiss}>{props.dismissButtonLabel}</Button>
                <Button onClick={props.onConfirm}>{props.confirmationButtonLabel}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ActionConfirmationDialog;
