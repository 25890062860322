import axios, {AxiosResponse} from 'axios';
import ApiAbstractRepository from '../../shared/repository/ApiAbstractRepository';
import {OrganizationMemberResponse} from "../domain/to/OrganizationMemberResponse";

export class ApiOrganizationMemberRepository extends ApiAbstractRepository{

    async organizationMemberships(userId: string): Promise<OrganizationMemberResponse> {

        const config = this.authConfig();

        return await axios
            .get(this.baseUrl + `/users/${userId}/organization-members`, config)
            .then( response => this.handleSuccessOrganizationsResponse(response))
            .catch( () => {
                return  {
                    organizationMemberList: [],
                    userList: [],
                    organizationList: []
                }
            });
    }

    private handleSuccessOrganizationsResponse(response: AxiosResponse): OrganizationMemberResponse {

        //TODO: Use mappers to transform json to real entities
        return {
            organizationMemberList: response.data.data,
            organizationList: response.data.related.organization,
            userList: response.data.related.user,
        }
    }

}
