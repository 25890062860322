
interface AbstractOption {
    path: string,
    label: string
}

export const currentOptionFinder =
    (
        currentPath: string,
        options: AbstractOption[],
        defaultOption: AbstractOption
    ): AbstractOption => {
    const currentOption =  options.find(option => currentPath.includes(option.path) || currentPath.includes(option.label.toLowerCase()));
    return currentOption || defaultOption;
}


