import InfoDialogProps from './InfoDialogProps';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

function InfoDialog(props: InfoDialogProps) {

    return (
        <Dialog onClose={props.onClose} open={props.open}>
            <DialogTitle>
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{props.body}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} autoFocus>{props.buttonLabel}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default InfoDialog;
