
interface LinkProps {
    to: string,
    children: string
    color?: string,
    variant?: any,
    classes?: string
}

export const defaultProps = {
    color: 'inherit',
    variant: 'inherit',
    classes: {}
}

export default LinkProps;
