import {useParams} from 'react-router-dom';
import {useAppDispatch} from '../../../shared/hooks/stateHooks';
import {useEffect} from 'react';
import PrivateLayout from '../../../shared/components/PrivateLayout/PrivateLayout';
import {Box, CircularProgress, Container} from '@mui/material';
import {useAforableById} from "../../../aforable/hooks/useAforableById";
import {fetchAforableById} from "../../../aforable/state/aforableSlice";
import AforableUpgradePremium from "../../components/AforableUpgradePremium/AforableUpgradePremium";
import {fetchOffersByAforable} from "../../../offer/state/offerSlice";
import {useOfferCollectionByAforable} from "../../../offer/hooks/useOfferCollectionByAforable";
import {useAforableListByOrganizationId} from "../../../aforable/hooks/useAforableListByOrganizationId";
import {useActiveOrganization} from "../../../organization/hooks/useActiveOrganization";

function AforableUpgradePremiumPage() {

    const params = useParams();
    const dispatch = useAppDispatch();

    const aforableId = params.id!;

    const organization = useActiveOrganization();
    const aforable = useAforableById(aforableId);
    const aforableList = useAforableListByOrganizationId(organization?.id)
    const offerCollection = useOfferCollectionByAforable(aforableId);

    useEffect(() => {
        dispatch(fetchAforableById({aforableId}));
        dispatch(fetchOffersByAforable({aforableId}));
    }, [aforableId, dispatch]);

    function isContentLoaded() {
        return aforable
            && !!aforableList.length
            && !offerCollection.isEmpty();
    }

    return (
        <PrivateLayout>
            <Container maxWidth={'sm'} sx={{px: 0}}>
                { isContentLoaded() ? (
                    <>
                        <AforableUpgradePremium
                            selectedAforable={aforable}
                            aforables={aforableList}
                            offers={offerCollection}
                        />
                    </>
                ): (
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress/>
                    </Box>
                )}
            </Container>
        </PrivateLayout>
    );
}

export default AforableUpgradePremiumPage;
