import {AxiosError, AxiosRequestConfig} from 'axios';
import ApiError from '../domain/error/ApiError';
import {LocalStorageAuthRepository} from '../../auth/repository/LocalStorageAuthRepository';

class ApiAbstractRepository {

    protected baseUrl: string = process.env.REACT_APP_API_URL as string;
    protected authRepository: LocalStorageAuthRepository;

    constructor() {
        this.authRepository = new LocalStorageAuthRepository();
    }

    protected authConfig(): AxiosRequestConfig {
        const token = this.authRepository.authStatus().token;

        return {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
    }

    protected handleFailureRequest(erroredResponse: AxiosError): never {

        const data = erroredResponse.response?.data as any;
        const meta = data.meta;
        const error = meta.error;

        throw new ApiError(meta.code, error.code, error.message);

    }

}

export default ApiAbstractRepository;
