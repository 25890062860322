import {Aforable} from "../Aforable";
import {AforableType} from "../AforableType";
import {AforableCapacity} from "../AforableCapacity";

export class AforableBuilder {

    execute(object: any): Aforable {
        return new Aforable(
            object.id,
            object.name,
            object.slug,
            AforableType.buildFromString(object.type),
            new AforableCapacity(
                object.capacity.maxAllowedCapacity,
                object.capacity.currentUsedCapacity,
            ),
            object.organizationId,
            new Date(object.createdAt),
            new Date(object.updatedAt),
            object.website
        );
    }
}