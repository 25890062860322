import React from 'react';
import AuthHeader from '../../shared/AuthHeader/AuthHeader';
import {useTranslate} from "../../../../shared/hooks/useTranslate";

function ForgotPasswordHeader() {

    const { t } = useTranslate('auth', 'ForgotPasswordPage.ForgotPasswordHeader')

    return (
        <AuthHeader>{t('forgot_password')}</AuthHeader>
    );
}



export default ForgotPasswordHeader;
