import PrivateLayout from '../../../shared/components/PrivateLayout/PrivateLayout';
import OrganizationSelector from '../../components/OrganizationSelector/OrganizationSelector';
import OrganizationDetail from '../../components/OrganizationDetail/OrganizationDetail';
import {Box, CircularProgress, Container} from '@mui/material';
import OrganizationMemberList from '../../../organizationMember/components/OrganizationMemberList/OrganizationMemberList';
import OrganizationOrders from '../../../order/components/OrganizationOrders/OrganizationOrders';
import {useEffect} from 'react';
import {selectActiveOrganization} from '../../state/organizationSlice';
import {useAppDispatch} from '../../../shared/hooks/stateHooks';
import {useNavigate, useParams} from 'react-router-dom';
import {
    useIsUserLoggedPartOfAnOrganization
} from "../../../organizationMember/hooks/useIsUserLoggedPartOfAnOrganization";
import {fetchOrganizationMemberships} from "../../../organizationMember/state/organizationMemberSlice";
import {useUserLogged} from "../../../auth/hooks/useUserLogged";
import {fetchOrdersByOrganization} from "../../../order/state/orderSlice";
import {useActiveOrganization} from "../../hooks/useActiveOrganization";

function OrganizationPage() {

    const dispatch = useAppDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const isUserPartOfAnOrganization = useIsUserLoggedPartOfAnOrganization();
    const organization = useActiveOrganization();
    const userLogged = useUserLogged();

    useEffect(() => {

        if (!userLogged?.id) {
            return;
        }

        dispatch(fetchOrganizationMemberships());
    }, [dispatch, userLogged?.id, params?.id]);

    useEffect(() => {
        dispatch(fetchOrdersByOrganization({organizationId: params.id!}));
    }, [dispatch, params?.id]);

    useEffect(() => {

        if (isUserPartOfAnOrganization) {
            return;
        }

        navigate('/organizations/new');
    }, [isUserPartOfAnOrganization, navigate]);

    useEffect(() => {

        if (organization?.id === params?.id) {
            return;
        }

        dispatch(selectActiveOrganization(params.id!));

    }, [params.id, dispatch, organization?.id]);

    const isContentLoaded = (): boolean => {
        return !!organization;
    };


    return (
        <PrivateLayout>
            <Container maxWidth={'sm'} sx={{px: 0}}>
                { isContentLoaded() ? (
                    <>
                        <OrganizationSelector/>
                        <Box sx={{m: 2}}/>

                        <OrganizationDetail/>
                        <Box sx={{m: 2}}/>

                        <OrganizationMemberList/>
                        <Box sx={{m: 2}}/>

                        <OrganizationOrders/>
                    </>
                ) : (
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress/>
                    </Box>
                )}

            </Container>
        </PrivateLayout>
    );
}

export default OrganizationPage;
