import AuthLayout from '../../components/shared/AuthLayout/AuthLayout';
import SignUpHeader from '../../components/SignUp/SignUpHeader/SignUpHeader';
import SignUpForm from '../../components/SignUp/SignUpForm/SignUpForm';
import React, {useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../shared/hooks/stateHooks";
import {User} from "../../../user/domain/User";
import {useUUID} from "../../../shared/hooks/useUUID";
import {createUser} from "../../../user/state/userSlice";
import {PrePopulatedUserData} from "../../domain/to/PrePopulatedUserData";
import SignUpConfirmationDialog from "../../components/SignUp/SignUpConfirmationDialog/SignUpConfirmationDialog";
import {useTranslate} from "../../../shared/hooks/useTranslate";


function SignUpPage() {

    const navigate = useNavigate();
    const location = useLocation();
    const uuid = useUUID();
    const dispatch = useAppDispatch();
    const { language } = useTranslate();

    const prePopulatedUserInfo = (location.state as any)?.userData as PrePopulatedUserData | undefined;

    const [inProgress, setInProgress] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    const onRegistrationFormSubmit = async (values: any, actions: any): Promise<void> => {
        setInProgress(true);

        const user = buildUserFromValues(values);
        const password = values.password as string;

        try {
            await dispatch(createUser({user, password})).unwrap();
            setModalOpen(true);
        } catch (error) {
            actions.setFieldError('passwordConfirmation', 'Something went wrong. Try again later.')
        }

        setInProgress(false);
    };

    const buildUserFromValues = (values: any): User => {
        return {
            id: uuid,
            email: values.email as string,
            firstName: values.firstName as string,
            lastName: values.lastName as string,
            locale: language,
            roles: ["ROLE_AFORABLE_OWNER", "ROLE_AFORABLE_CONTROLLER"], // TODO: Move this logic to back-end
            source: prePopulatedUserInfo?.source || 'EMAIL',
            thirdPartyId: prePopulatedUserInfo?.thirdPartyId
        };
    }

    const onSuccessModalClosed = () => {
        setModalOpen(false);
        navigate(`/login`);
    }

    return (
        <AuthLayout>
            <SignUpHeader/>
            <SignUpForm
                handleSubmit={onRegistrationFormSubmit}
                inProgress={inProgress}
                prePopulatedUserData={prePopulatedUserInfo}
            />
            <SignUpConfirmationDialog
                isModalOpen={isModalOpen}
                onSuccessModalClosed={onSuccessModalClosed}
            />
        </AuthLayout>
    );
}

export default SignUpPage;
