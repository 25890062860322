import {useParams} from 'react-router-dom';
import {useAppDispatch} from '../../../shared/hooks/stateHooks';
import {useEffect} from 'react';
import {fetchAforableBySlug} from '../../state/aforableSlice';
import {Box, CircularProgress, Container} from '@mui/material';
import {useAforableBySlug} from "../../hooks/useAforableBySlug";
import ViewAforable from "../../components/ViewAforable/ViewAforable";
import PublicLayout from "../../../shared/components/PublicLayout/PublicLayout";


function ViewAforablePage() {

    const params = useParams();
    const aforable = useAforableBySlug(params.slug);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const slug: string = params.slug!;
        dispatch(fetchAforableBySlug({slug}));

    }, [dispatch, params.slug]);

    return (
        <PublicLayout>
            <Container maxWidth={'sm'} sx={{px: 0}}>
                { aforable ? (
                    <>
                        <ViewAforable aforable={aforable}/>
                    </>
                ): (
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress/>
                    </Box>
                )}
            </Container>
        </PublicLayout>
    );
}

export default ViewAforablePage;
