import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import LogInPage from '../../../auth/pages/LogInPage/LogInPage';
import SignUpPage from '../../../auth/pages/SignUpPage/SignUpPage';
import ForgotPasswordPage from '../../../auth/pages/ForgotPasswordPage/ForgotPasswordPage';
import AforableListPage from '../../../aforable/pages/AforableListPage/AforableListPage';
import OrganizationPage from '../../../organization/pages/OrganizationPage/OrganizationPage';
import ProfilePage from '../../../user/pages/ProfilePage/ProfilePage';
import AppBarFactory from '../AppBarFactory/AppBarFactory';
import {createTheme, ThemeProvider} from '@mui/material';
import EditProfilePage from '../../../user/pages/EditProfilePage/EditProfilePage';
import EditOrganizationPage from '../../../organization/pages/EditOrganizationPage/EditOrganizationPage';
import CreateOrganizationPage from '../../../organization/pages/CreateOrganizationPage/CreateOrganizationPage';
import AforableDetailPage from '../../../aforable/pages/AforableDetailPage/AforableDetailPage';
import EditAforablePage from "../../../aforable/pages/EditAforablePage/EditAforablePage";
import CreateAforablePage from "../../../aforable/pages/CreateAforablePage/CreateAforablePage";
import AforableCapacityManagementPage
    from "../../../aforable/pages/AforableCapacityManagementPage/AforableCapacityManagementPage";
import ViewAforablePage from "../../../aforable/pages/ViewAforablePage/ViewAforablePage";
import ResetPasswordPage from "../../../auth/pages/ResetPasswordPage/ResetPasswordPage";
import AforableUpgradePremiumPage from "../../../order/pages/AforableUpgradePremiumPage/AforableUpgradePremiumPage";
import AforableUpgradePremiumSuccessPage
    from "../../../order/pages/AforableUpgradePremiumSuccessPage/AforableUpgradePremiumSuccessPage";

function App() {

    const theme = createTheme();

    return (
      <>
          <ThemeProvider theme={theme}>
              <BrowserRouter>
                  <AppBarFactory/>
                  <Routes>
                      <Route path="/">
                          <Route index element={<LogInPage />} />
                          <Route path="login" element={<LogInPage/>}/>
                          <Route path="signup" element={<SignUpPage/>}/>
                          <Route path="forgot-password" element={<ForgotPasswordPage/>}/>
                          <Route path="reset-password/:token" element={<ResetPasswordPage/>}/>
                          <Route path="spaces">
                              <Route index element={<AforableListPage/>}/>
                              <Route path=":id">
                                  <Route path="detail" element={<AforableDetailPage/>}/>
                                  <Route path="edit" element={<EditAforablePage/>}/>
                                  <Route path="capacity-management" element={<AforableCapacityManagementPage/>}/>
                                  <Route path="upgrade-premium">
                                      <Route index element={<AforableUpgradePremiumPage/>}/>
                                      <Route path=":orderId/completion" element={<AforableUpgradePremiumSuccessPage/>}/>
                                  </Route>
                              </Route>
                              <Route path="new" element={<CreateAforablePage/>}/>
                          </Route>
                          <Route path="organizations">
                              <Route path=":id">
                                  <Route index element={<OrganizationPage/>}/>
                                  <Route path="edit" element={<EditOrganizationPage/>}/>
                              </Route>
                              <Route path="new" element={<CreateOrganizationPage/>}/>
                          </Route>
                          <Route path="profile">
                              <Route index element={<ProfilePage/>}/>
                              <Route path="edit" element={<EditProfilePage/>}/>
                          </Route>
                          <Route path="s">
                              <Route path=":slug" element={<ViewAforablePage/>}/>
                          </Route>
                      </Route>
                  </Routes>
              </BrowserRouter>
          </ThemeProvider>
      </>
    );
}

export default App;
