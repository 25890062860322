import {
    Box,
    Card,
    CardContent,
    Typography
} from '@mui/material';

import React, {useState} from 'react';
import {AforableCapacityManagementProps} from "./AforableCapacityManagementProps";
import {useAppDispatch} from "../../../shared/hooks/stateHooks";
import {
    decreaseAforableUsedCapacity,
    increaseAforableUsedCapacity,
    resetAforableUsedCapacity
} from "../../state/aforableSlice";
import ActionConfirmationDialog from "../../../shared/components/ActionConfirmationDialog/ActionConfirmationDialog";
import AforableCapacityDecreaseControl from "../AforableCapacityManagementControl/AforableCapacityDecreaseControl";
import AforableCapacityIncreaseControl from "../AforableCapacityManagementControl/AforableCapacityIncreaseControl";
import AforableCapacityResetControl from "../AforableCapacityManagementControl/AforableCapacityResetControl";
import {useTranslate} from "../../../shared/hooks/useTranslate";


function AforableCapacityManagement({aforable}: AforableCapacityManagementProps) {

    const dispatch = useAppDispatch();
    const [isDraftValue, setIsDraftValue] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t, td } = useTranslate('aforable', 'AforableCapacityManagementPage.AforableCapacityManagement');

    const increaseUsedCapacity = async () => {
        await updateUsedCapacityByAction(increaseAforableUsedCapacity({aforable}));
    };

    const decreaseUsedCapacity = async () => {
        await updateUsedCapacityByAction(decreaseAforableUsedCapacity({aforable}));
    };

    const resetUsedCapacity = async () => {
        setIsModalOpen(false);
        await updateUsedCapacityByAction(resetAforableUsedCapacity({aforable}));
    };

    const updateUsedCapacityByAction = async (action: any) => {
        setIsDraftValue(true);
        await dispatch(action).unwrap();
        setIsDraftValue(false);
    };

    return (
        <>
            <Card>
                <CardContent sx={{pb: 0}}>
                    <Typography component="div" variant="h5">{aforable.name}</Typography>
                    <Typography variant="body2" color="text.secondary">{t('aforable_capacity_management_sub')}</Typography>
                    <div id="current-used-capacity">
                        <Typography align="center" sx={{
                            pt: 2,
                            fontWeight: 600,
                            fontSize: 100,
                            color: isDraftValue ? 'gray' : 'black'
                        }}>
                            {aforable.capacity.currentUsedCapacity}
                        </Typography>
                    </div>

                    <Box id="basic-controls" sx={{display: "flex", justifyContent: "space-around"}}>
                        <AforableCapacityDecreaseControl
                            isDisabled={aforable.capacity.isCurrentUsedCapacityAtMinimum()}
                            onClick={decreaseUsedCapacity}
                        />
                        <AforableCapacityIncreaseControl
                            isDisabled={aforable.capacity.isCurrentUsedCapactyAtMaximum()}
                            onClick={increaseUsedCapacity}
                        />
                    </Box>

                    <Box id="basic-controls" sx={{display: "flex", justifyContent: "space-around", pt: 2}}>
                        <AforableCapacityResetControl
                            isDisabled={aforable.capacity.isCurrentUsedCapacityAtMinimum()}
                            onClick={() => setIsModalOpen(true)}
                        />
                    </Box>

                </CardContent>
            </Card>

            <ActionConfirmationDialog
                open={isModalOpen}
                title={t('reset_capacity_modal')}
                body=""
                dismissButtonLabel={td('action.cancel')}
                confirmationButtonLabel={td('action.reset')}
                onDismiss={() => setIsModalOpen(false)}
                onConfirm={resetUsedCapacity}
            />
        </>
    );
}

export default AforableCapacityManagement;
