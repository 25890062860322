import {useAppDispatch} from "../../../../../../shared/hooks/stateHooks";
import {loginWithGoogle} from "../../../../../state/authSlice";
import {useJwtDecoder} from "../../../../../hooks/useJwtDecoder";
import {AuthResponse} from "../../../../../domain/to/AuthResponse";
import {PrePopulatedUserData} from "../../../../../domain/to/PrePopulatedUserData";
import {usePrepopulatedSignUpNavigate} from "../../../../../hooks/usePrepopulatedSignUpNavigate";
import {GooglePersonalizedLoginButton} from "react-google-identity-gsi";
import {useTranslate} from "../../../../../../shared/hooks/useTranslate";

function GoogleLogin() {

    const dispatch = useAppDispatch();
    const { language } = useTranslate();
    const decode = useJwtDecoder();
    const signUpNavigate = usePrepopulatedSignUpNavigate();

    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

    const onUserAuthenticatedWithGoogle = async (data: any) => {
        try {
            await dispatch(loginWithGoogle({googleToken: data.credential})).unwrap();
        } catch (e) {
            manageLoginFailure(e as AuthResponse, data);
        }
    };

    const manageLoginFailure = (failedAuthResponse: AuthResponse, data: any) => {

        if (!failedAuthResponse.hasFailed) {
            // Tu que merdes fas aquí?
            // TODO: Show an error!
            return;
        }

        if (failedAuthResponse.errorCode !== 'BAD_CREDENTIALS_EXCEPTION') {
            // Unexpected error
            // TODO: Show an error!
            return;
        }

        const decodedData = decode(data.credential);
        const userData = buildPrePopulatedUserDataFromGoogleResponse(decodedData);
        signUpNavigate(userData);
    };

    const buildPrePopulatedUserDataFromGoogleResponse = (decodedData: any): PrePopulatedUserData => {
        return {
            email: decodedData.email,
            firstName: decodedData.given_name,
            lastName: decodedData.family_name,
            thirdPartyId: decodedData.sub,
            source: "GOOGLE"
        };
    }


    const buttonCustomization = {
        locale: language,
        text: 'continue_with',
        size: 'large',
        width: '300',
    };

    return (
        <GooglePersonalizedLoginButton
            clientId={googleClientId}
            buttonCustomization={buttonCustomization}
            onUserAuthenticationSucceeded={onUserAuthenticatedWithGoogle}
        />
    );

}

export default GoogleLogin;
