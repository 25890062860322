import {OrderMetadata} from "./OrderMetadata";
import {OrderMetadataSerialization} from "../state/serialization/OrderMetadataSerialization";

export class StripeOrderMetadata extends OrderMetadata {

    constructor(
        private _definition: any,
        private _response: any
    ) {
        super();
    }

    checkoutURL(): string {
        return this._response.url;
    }

    static provider(): string {
        return 'PROVIDER_STRIPE';
    }

    serialize(): OrderMetadataSerialization {
        return {
            provider: StripeOrderMetadata.provider(),
            definition: this._definition,
            response: this._response
        }
    }


}