import InfoDialog from "../../../../shared/components/InfoDialog/InfoDialog";
import React from "react";
import {SignUpConfirmationDialogProps} from "./SignUpConfirmationDialogProps";
import {useTranslate} from "../../../../shared/hooks/useTranslate";

function SignUpConfirmationDialog(props: SignUpConfirmationDialogProps) {

    const { t, td } = useTranslate('auth', 'SignUpPage.SignUpConfirmationDialog');

    return (
        <InfoDialog
            open={props.isModalOpen}
            title={t('registration_completed')}
            body={t('registration_completed_sub')}
            buttonLabel={td('info.got_it')}
            onClose={props.onSuccessModalClosed}
        />
    );
}

export default SignUpConfirmationDialog;