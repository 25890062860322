import * as Yup from "yup";
import {CreateAforableFormProps} from "./CreateAforableFormProps";
import {useFormik} from "formik";
import AforableFormBase from "../AforableFormBase/AforableFormBase";
import React, {useState} from "react";
import {Avatar, Box, FormControl, TextField} from "@mui/material";
import {InsertLink} from "@mui/icons-material";
import {useTranslate} from "../../../shared/hooks/useTranslate";

function CreateAforableForm(props: CreateAforableFormProps) {

    const { t, td } = useTranslate('aforable')

    const [isSubmitDisabled, setSubmitDisabled] = useState(false);

    const initialValues = {
        name: '',
        type: '',
        maxAllowedCapacity: 50,
        slug: '',
        website: '',
    };

    const validationSchema = Yup.object({
        name: Yup.string().required(td('validation.required')),
        type: Yup.string().required(td('validation.required')),
        slug: Yup.string().required(td('validation.required')).matches(/^[a-z\d]+(-?[a-z\d]+)*$/i,td('validation.slug')),
    });

    const onSubmit = async (values: any) => {
        setSubmitDisabled(true);
        await props.onSubmit(values);
        setSubmitDisabled(false);
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    const renderSlugFormField = () => {

        return (
            <Box maxWidth={'sm'} sx={{mt: 3, minWidth: 280, display: 'flex', alignItems: 'center'}}>
                <Avatar sx={{bgcolor: 'primary.main', mr: 2}}>
                    <InsertLink/>
                </Avatar>
                <FormControl fullWidth>
                    <TextField
                        id="slug"
                        name="slug"
                        label={`${t('Component.AforableFormBase.slug')} *`}
                        variant="outlined"
                        type="text"
                        value={formik.values.slug}
                        onChange={formik.handleChange}
                        error={formik.touched.slug && Boolean(formik.errors.slug)}
                        helperText={formik.errors.slug && formik.touched.slug && String(formik.errors.slug)}
                    />
                </FormControl>
            </Box>
        );
    }

    return (
        <>
            <AforableFormBase
                formik={formik}
                cardTitle={t('CreateAforablePage.CreateAforableForm.create_space')}
                submitLabel={td('action.create')}
                isSubmitDisabled={isSubmitDisabled}
                slugFormField={renderSlugFormField()}
            />
        </>
    );
}


export default CreateAforableForm;