import {
    Box,
    Card,
    CardContent,
    Typography
} from '@mui/material';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {ViewAforableProps} from './ViewAforableProps';
import {Chart, ArcElement} from 'chart.js'
import dayjs from "dayjs";
import {useTranslate} from "../../../shared/hooks/useTranslate";


function ViewAforable({aforable}: ViewAforableProps) {

    const { t, language} = useTranslate('aforable', 'ViewAforablePage.ViewAforable');

    Chart.register(ArcElement);

    const backgroundColor = (): string => {

        const percentage = aforable.usedCapacityPercentage();

        if (percentage >= 80) {
            return '#db0909';
        }

        if (percentage >= 60) {
            return '#f8cb2d';
        }

        return  '#5AA454';

    };

    const data = {
        datasets: [
            {
                data: [aforable.usedCapacityPercentage(), 100 - aforable.usedCapacityPercentage()],
                backgroundColor: [backgroundColor(), 'lightgray'],
            },
        ]
    };

    const options = {
        legend: {
            display: false
        },
        tooltips: {
            enabled: false
        },
        hover: {
            mode: null
        },
        animation: false
    };

    return (
        <Card>
            <CardContent sx={{pb: 0}}>
                <Typography component="div" variant="h5" align="center">{aforable.name}</Typography>
                <Box sx={{display: "flex", justifyContent: 'center', pt: 3}}>
                    <Box sx={{maxWidth: "250px"}}>
                        <Doughnut data={data} options={options as any}/>
                    </Box>
                </Box>
                <Box sx={{pt: 2}}>
                    <Typography component="div">
                        <b>{t('current_used_capacity')}: </b>{t('current_used_capacity_value', {current: aforable.capacity.currentUsedCapacity, max: aforable.capacity.maxAllowedCapacity})}
                    </Typography>

                    <Typography component="div">
                        <span style={{fontWeight: 'bold'}}>{t('current_used_capacity_percentage')}: </span>{t('current_used_capacity_percentage_value', {value: aforable.capacity.usagePercentage()})}
                    </Typography>

                    <Typography component="div">
                        <span style={{fontWeight: 'bold'}}>{t('last_update')}: </span> {dayjs(aforable.updatedAt).locale(language).fromNow(false)}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}

export default ViewAforable;
