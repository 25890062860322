import {Order} from "./Order";
import {Collection} from "../../shared/domain/Collection";

export class OrderCollection extends Collection<Order> {

    constructor(orders: Order[]) {
        super(orders);
        this.sortByDescendentCreation();
    }

    private sortByDescendentCreation() {
        this.items = this.items.sort(function (a: Order, b: Order): number {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
    }

    public hasAnyActiveOrder(): boolean {
        return this.items.reduce(
            (carry, current): boolean => carry || current.isActive,
            false
        );
    }

    public isPremiumExpired(): boolean {
        return !this.isEmpty() && !this.hasAnyActiveOrder();
    }

    public mostRecentlyPurchasedOrder() {
        return this.items[0];
    }

}