import React from 'react';
import AuthHeader from '../../shared/AuthHeader/AuthHeader';
import {useTranslate} from "../../../../shared/hooks/useTranslate";

function SignUpHeader() {

    const { td } = useTranslate();

    return (
        <AuthHeader>{td('action.signup')}</AuthHeader>
    );
}



export default SignUpHeader;
