import {Divider} from "@mui/material";
import React from "react";
import {useTranslate} from "../../../../shared/hooks/useTranslate";

function LogInDivider() {

    const { t } = useTranslate('auth', 'LogInPage.LogInDivider');

    return (
        <Divider sx={{ m: 3, width: "85%" }}>{t('or')}</Divider>
    );
}

export default LogInDivider;