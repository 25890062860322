import {OfferCollection} from "../domain/OfferCollection";
import {useOfferList} from "./useOfferList";
import {Offer} from "../domain/Offer";

export const useOfferCollectionByAforable = (aforableId?: string): OfferCollection => {
    const offerState = useOfferList();
    const orders = offerState
        .toArray()
        .filter((offer: Offer) => offer.aforableId === aforableId);
    return new OfferCollection(orders);
}
